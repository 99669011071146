/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

import React, { useRef } from "react"
import { ListGroup, Accordion, Button, Card, Col, Row } from "react-bootstrap"

import ToolbarIcon from "./ToolbarIcon"
import WithCondition from "./WithCondition"

const convertToString = target => {
  if (typeof target === "object") {
    return JSON.stringify(target, null, 2)
  }
  return String(target)
}
const getListItemClassName = (index, length) => {
  if (index === 0) {
    return "border-top-0"
  }
  if (index === length - 1) {
    return "border-bottom-0"
  }
  return ""
}
const DetailInfo = ({ label, element }) => {
  const LabelInterface = ({ children }) => (
    <>
      <Col className="font-weight-bold col-4">{label}</Col>
      <Col className="col-8">{children}</Col>
    </>
  )
  if (["accessModeKind", "kind", "replicas", "deviceType"].includes(label)) {
    return (
      <LabelInterface>
        <div>{element.label}</div>
      </LabelInterface>
    )
  }
  if (label === "volumes") {
    return (
      <LabelInterface>
        {element.length > 0 &&
          element.map((vol, i) => {
            return (
              <div key={`${label}-${vol.name}-${i}`}>
                <Row key={`volumes-name-${vol.name}-${i}`}>
                  <Col className="font-weight-bold col-4">name</Col>
                  <Col className="col-8">{vol.name}</Col>
                </Row>
                <Row key={`mount-path-${vol.name}-${i}`}>
                  <Col className="font-weight-bold col-4">mountPath</Col>
                  <Col className="col-8">{vol.mountPath}</Col>
                </Row>
              </div>
            )
          })}
      </LabelInterface>
    )
  }
  if (label === "configMaps") {
    return (
      <LabelInterface>
        <div key={`${label}-config-maps`}>
          {element.length > 0 &&
            element.map((configMap, i) => {
              return (
                <div key={`config-maps-${configMap.name}-${i}`}>
                  <Row key={`config-maps-${configMap.name}-${i}-row`}>
                    <Col className="font-weight-bold col-4">name</Col>
                    <Col className="col-8">{configMap.name}</Col>
                  </Row>
                </div>
              )
            })}
        </div>
      </LabelInterface>
    )
  }
  if (label === "service") {
    return (
      <LabelInterface>
        <div key={`${label}-container-port`}>
          <Row key="service-ct-port">
            <Col className="font-weight-bold col-4">containerPort</Col>
            <Col className="col-8">{element.containerPort}</Col>
          </Row>
          <Row key="service-node-port">
            <Col className="font-weight-bold col-4">nodePort</Col>
            <Col className="col-8">{element.nodePort}</Col>
          </Row>
          <Row key="service-path">
            <Col className="font-weight-bold col-4">path</Col>
            <Col className="col-8">{element.path}</Col>
          </Row>
        </div>
      </LabelInterface>
    )
  }
  return (
    <LabelInterface>
      <div>{convertToString(element)}</div>
    </LabelInterface>
  )
}
const DetailService = ({ element }) => {
  const clsName = "border-0"
  return (
    <>
      <ListGroup.Item className={`${clsName}`}>
        <Row key="detail-service-container-port">
          <Col className="font-weight-bold col-4">containerPort</Col>
          <Col className="col-8">{element.containerPort}</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className={`${clsName}`}>
        <Row key="detail-service-node-port">
          <Col className="font-weight-bold col-4">nodePort</Col>
          <Col className="col-8">{element.nodePort}</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className={`${clsName}`}>
        <Row key="detail-service-path">
          <Col className="font-weight-bold col-4">path</Col>
          <Col className="col-8">{element.path}</Col>
        </Row>
      </ListGroup.Item>
    </>
  )
}
const DetailInference = ({ element }) => {
  const clsName = "border-left-0 border-right-0"
  return (
    <>
      <ListGroup.Item className={`${clsName} border-top-0`}>
        <Row key={`inference-${element.id}-name`}>
          <Col className="font-weight-bold col-4">name</Col>
          <Col className="col-8">{element.name}</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className={`${clsName}`}>
        <Row key={`inference-${element.id}-id`}>
          <Col className="font-weight-bold col-4">id</Col>
          <Col className="col-8">{element.id}</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className={`${clsName}`}>
        <Row key={`inference-${element.id}-endpoint`}>
          <Col className="font-weight-bold col-4">endpoint</Col>
          <Col className="col-8">{element.endpoint}</Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className={`${clsName} border-bottom-0`}>
        <Row key={`inference-${element.id}-token`}>
          <Col className="font-weight-bold col-4">token</Col>
          <Col className="col-8">{element.tokensList[0].token}</Col>
        </Row>
      </ListGroup.Item>
    </>
  )
}
const Collapses = ({
  label,
  content,
  type = null,
  onRemoveItem = () => {}
}) => {
  const type1Refs = useRef(new Map()).current

  const toggleClick = (type, index) => {
    const refs = type1Refs

    for (const [mapIndex, value] of refs.entries()) {
      if (index === mapIndex) {
        value.classList.toggle("active")
      } else {
        value.classList.remove("active")
      }
    }
  }
  const DetailViewHaveType = WithCondition(DetailInference, DetailService)
  return (
    <Accordion className="col-12 mb-2">
      <div className="accordion-tit mt-2">{label}</div>
      {content.length > 0 &&
        content.map((element, idx) => {
          return (
            <Card key={`${label}-card-${idx}`}>
              <div className="d-flex">
                <div className="pt-2">
                  <ToolbarIcon
                    visible
                    btnClassName="d-flex btn btn-ghost-danger icon-only"
                    iconClassName="glyphicons-outline_circle_remove"
                    tooltip="Remove"
                    onClick={() => onRemoveItem(idx)}
                  />
                </div>
                <div className="col-11 px-0">
                  <div
                    className="card-header align-items-center"
                    id="headingOne"
                    ref={inst => type1Refs.set(idx, inst)}
                  >
                    <Accordion.Toggle
                      as={Button}
                      variant="btn btn-link pr-2"
                      eventKey={idx}
                      onClick={() => toggleClick(1, idx)}
                    >
                      {element.name}
                    </Accordion.Toggle>
                  </div>
                  <Accordion.Collapse eventKey={idx}>
                    <Card.Body className="d-flex">
                      <Col>
                        <ListGroup border="none">
                          {type ? (
                            <DetailViewHaveType
                              condition={type === "inferences"}
                              successProps={{ element }}
                              falsyProps={{ element }}
                            />
                          ) : (
                            Object.keys(element).length > 0 &&
                            Object.keys(element).map((key, i) => {
                              if (!element[key]) {
                                return null
                              }
                              return (
                                <ListGroup.Item
                                  key={`list-item-${key}-${i}`}
                                  className={`border-left-0 border-right-0 ${getListItemClassName(
                                    i,
                                    Object.keys(element).length
                                  )}`}
                                >
                                  <Row key={`${label}-${key}-${i}`}>
                                    <DetailInfo
                                      label={key}
                                      element={element[key]}
                                    />
                                  </Row>
                                </ListGroup.Item>
                              )
                            })
                          )}
                        </ListGroup>
                      </Col>
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              </div>
            </Card>
          )
        })}
    </Accordion>
  )
}

export default Collapses
