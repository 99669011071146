import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Dropdown, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import "./NotificationHistory.css";

const NotificationHistory = () => {
  const { auth, history } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const historyData = history.history;

  return (
    <div className="floating-icon-container">
      <Dropdown className="item-history">
        <Dropdown.Toggle
          className="dropdown-toggle icon-only"
          id="navbar-history"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <div className={`${!history.new && "d-none"}`}>
            <div className="new">
              <span className="sr-only">새로운 알림이 있습니다.</span>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faBell}
            className={isHovered ? "shake-icon" : ""}
            style={{
              fontSize: "1.8rem",
              backgroundColor: "black",
              border: "none",
              borderRadius: "50%",
              padding: "10px"
            }}
            aria-hidden="true"
            onClick={() => {
              dispatch.history.checkHistory(false);
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Card>
            <Card.Header className="history-header text-black-60 text-decoration-none">
              <strong>
                Alert ({`${historyData.length > 10 ? "10+" : historyData.length}`})
              </strong>
            </Card.Header>
            <Card.Body className="history-body p-0">
              <ListGroup as="ul" variant="flush" className="history-group">
                {historyData.length > 0 &&
                  historyData.map((value, i) => (
                    <ListGroup.Item as="li" key={`history-${i}`}>
                      <div className="p-2 text-black-50 text-decoration-none">
                        <div className="d-flex justify-content-between">
                          <strong> {value.log} </strong>
                        </div>
                        <small className="d-block mt-1 text-black-38">{value.date}</small>
                      </div>
                    </ListGroup.Item>
                  ))}
                {historyData.length === 0 && (
                  <ListGroup.Item as="li" key="history-none" className="w-100">
                    <span className="text-black-50 text-decoration-none w-100">
                      There is no Notice.
                    </span>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NotificationHistory;
