/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */

/**
 * @namespace inference
 * @memberof models
 */

import axios from "axios"

import produce from "immer"
import { dateFormat, invert } from "utils"
import {
  CreateInferenceRequest,
  Inference,
  ListInferenceResponse,
  ListInstanceResponse,
  ShareInferenceRequest,
  ListResourceUsageResponse,
  ListStatisticsResponse,
  Restriction,
  Token,
  UpdateTokenRequest,
  ListLogResponse,
  ServerStatus,
  DataType,
  ListTokenResponse,
  Status,
  DownloadToken,
  Role
} from "arti-proto"
import {
  requestProtoGet,
  requestProtoPost,
  requestProtoPut,
  requestProtoDelete
} from "./api"

const SAPEON_X220 = "skt.com/aix_v1"
const SAPEON_X330 = "sapeon.com/snx3"
const NVIDIA_GPU = "nvidia.com/gpu"

const getDataTypeNameByValue = invert(DataType)
const getStatusNameByValue = invert(Status)
const getRoleNameByValue = invert(Role)


const makeRestrictionProtoMessage = restriction => {
  const ret = new Restriction()
  if (Array.isArray(restriction.ip)) {
    restriction.ip.forEach(({ address }) => {
      const ipRestriction = new Restriction.Ip()
      ipRestriction.setAddress(address)
      ret.addIp(ipRestriction)
    })
  }
  if (Array.isArray(restriction.android)) {
    restriction.android.forEach(({ packageName, sha1 }) => {
      const androidRestriction = new Restriction.Android()
      androidRestriction.setPackageName(packageName)
      androidRestriction.setSha1(sha1)
      ret.addAndroid(androidRestriction)
    })
  }
  if (Array.isArray(restriction.ios)) {
    restriction.ios.forEach(({ bundleName }) => {
      const iosRestriction = new Restriction.Ios()
      iosRestriction.setBundleName(bundleName)
      ret.addIos(iosRestriction)
    })
  }
  return ret
}

/**
 * @memberof models.inference
 * @const
 * @type {Object}
 * @property {Array} [list] - List of Infernece Service
 * @property {Object} [serviceData] - Data of Inference Service
 * @property {Object}  [modelStatus] - Model Status
 * @property {Array} [sharedList] - Shared Inference Service List
 * @property {Array} [instancesOptions]  - Instances Count Options
 * @property {Array} [deviceOptions] - Device Options
 * @property {Array} [gpuCountOptions] - Gpu Count Options
 * @property {Array} [backendVersion] - Backend Version Options
 */
const initialState = {
  list: [],
  serviceData: {},
  serviceMenus: [],
  modelStatus: undefined,
  sharedList: [],
  instancesOptions: [],
  deviceOptions: [],
  gpuCountOptions: [],
  backendVersion: []
}
const SAPEON = "skt.com/aix_v1"
const inference = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Object} payload
     * @param {Array} payload.list - List of Inference Service
     * @return {State} - Changed State
     */
    setList(state, payload) {
      const sorted = payload.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
      )
      return produce(state, draft => {
        draft.list = sorted
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Boolean} payload - Activate: true, Deactivate: false
     * @return {State} - Changed State
     */
    setActivation(state, payload) {
      return produce(state, draft => {
        draft.serviceData.activated = payload
      })
    },
    setServiceMenus(state, payload) {
      return produce(state, draft => {
        draft.serviceMenus = payload
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Object} payload
     * @param {Array} payload.list - List of Inference Service
     * @return {State} - Changed State
     */
    setGpuCountOptions(state, payload) {
      return produce(state, draft => {
        draft.gpuCountOptions = payload
      })
    },

    setBackendVersion(state, payload) {
      return produce(state, draft => {
        draft.backendVersion = payload
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Boolean} payload - Activate: true, Deactivate: false
     * @return {State} - Changed State
     */
    setServiceData(state, payload) {
      return produce(state, draft => {
        draft.serviceData = payload
        draft.modelStatus = payload.modelStatus
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Boolean} payload - Activate: true, Deactivate: false
     * @return {State} - Changed State
     */
    clearServiceData(state) {
      return produce(state, draft => {
        draft.serviceData = initialState.serviceData
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Object} payload - modelStatus
     * @return {State} - Changed State
     */
    setModelStatus(state, payload) {
      return produce(state, draft => {
        draft.modelStatus = payload
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @return {State} - Changed State
     */
    clearModelStatus(state) {
      return produce(state, draft => {
        draft.modelStatus = initialState.modelStatus
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Array} payload - List of Shared Inference Service
     * @return {State} - Changed State
     */
    setSharedList(state, payload) {
      return produce(state, draft => {
        draft.sharedList = payload
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @return {State} - Changed State
     */
    clearSharedList(state) {
      return produce(state, draft => {
        draft.sharedList = initialState.sharedList
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Object} payload - Instance Options
     * @return {State} - Changed State
     */
    setInstanceOptions(state, payload) {
      return produce(state, draft => {
        draft.instancesOptions = payload
      })
    },
    /**
     * @memberof models.inference
     * @param {State} state - Current State
     * @param {Object} payload - Device Options
     * @return {State} - Changed State
     */
    setDeviceOptions(state, payload) {
      return produce(state, draft => {
        draft.deviceOptions = payload
      })
    },
  },
  effects: dispatch => ({
    /**
     * Reqeust get Service list
     * @memberof models.inference
     * @param {String} options Get list pagination option
     * @property {request} request
     * @property {query} request.query /infernece/list
     * @property {type} request.type GET
     * @return {Array} Response Service list
     */
    async getAll(options, state) {
      const { list } = state.inference
      const { data } = await requestProtoGet(`inference/list${options}`)
      const response = ListInferenceResponse.deserializeBinary(data).toObject()
      const inferencesList = response.inferencesList.map(v => {
        v.status = getStatusNameByValue[v.status]
        v.activated = v.status !== "STOP"
        return v
      })
      await dispatch.inference.setList(list.concat(inferencesList))
      return response.inferencesList
    },
    /**
     * Reqeust get My Service list
     * @memberof models.inference
     * @param {String} options Get list pagination option
     * @property {request} request
     * @property {query} request.query /infernece/my
     * @property {type} request.type GET
     * @return {Array} Response Service list
     */
    async getList(options, state) {
      const { list } = state.inference
      const { data } = await requestProtoGet(`inference/my${options}`)
      const response = ListInferenceResponse.deserializeBinary(data).toObject()
      const inferencesList = response.inferencesList.map(v => {
        v.status = getStatusNameByValue[v.status]
        v.activated = v.status !== "STOP"
        return v
      })
      await dispatch.inference.setList(list.concat(inferencesList))
      // for dashbaord service list dropdown
      this.setServiceMenus([
        { value: 0, label: "Top 5 inferences" },
        ...response.inferencesList.map(serviceEl => ({
          value: serviceEl.id,
          label: serviceEl.name
        }))
      ])
      return response.inferencesList
    },
    async getResourceUsage(payload) {
      const { from, serviceId, step, to } = payload
      const { data } = await requestProtoGet(
        `/inference/${serviceId}/usage?from=${from}&to=${to}&step=${step}`
      )
      const response = ListResourceUsageResponse.deserializeBinary(
        data
      ).toObject()
      return response
    },
    getInstancesOptions() {
      dispatch.inference.setInstanceOptions([
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 }
      ])
    },
    /**
     * Request get Device options using resource.getResources()
     * @memberof models.inference
     */
    async getDeviceOptions() {
      const defaultDeviceOptions = [
        { value: "cpu", label: "CPU" }
        // { value: "skt.com/aix_v1", label: "SAPEON" }
      ]

      const genLabel = val => {
        if (val === SAPEON_X220) {
          return "X220"
        }
        else if (val === SAPEON_X330) {
          return "X330"
        }
        else if (val === NVIDIA_GPU) {
          return "GPU"
        }
        else {
          return "CPU"
        }
      }
      try {
        const resources = await dispatch.resource.getResources()
        const deviceTypes = resources.map(deviceType => {
          return {
            value: deviceType.value,
            label: genLabel(deviceType.value),
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable
          }
        })
        const deviceOptions = [...defaultDeviceOptions, ...deviceTypes]

        await dispatch.inference.setDeviceOptions(deviceOptions)
      } catch (error) {
        await dispatch.inference.setDeviceOptions(defaultDeviceOptions)
      }
    },

    async getGroupDeviceOptions(groupname) {
      const defaultDeviceOptions = [
        { value: "cpu", label: "CPU" }
        // { value: "skt.com/aix_v1", label: "SAPEON" }
      ]
      const genLabel = val => {
        if (val === SAPEON) {
          return "x220"
        }
        return val.split("/").length > 1 ? val.split("/")[1] : val
      }
      try {
        // FIXME : Modify resource to groups
        const resources = await dispatch.groups.getGroupResources(`${groupname}`)
        const deviceTypes = resources.map(deviceType => {
          return {
            value: deviceType.value,
            label: genLabel(deviceType.value),
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable
          }
        })
        const deviceOptions = [...defaultDeviceOptions, ...deviceTypes]

        await dispatch.inference.setDeviceOptions(deviceOptions)
      } catch (error) {
        await dispatch.inference.setDeviceOptions(defaultDeviceOptions)
      }
    },
    async getGpuCountOptions() {
      dispatch.inference.setGpuCountOptions([
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 }
      ])
    },

    async getBackendVersion() {
      var baseUrl = window.artiference.env.HARBOR_URL
      var uri = '/api/v2.0/projects/images/repositories/aix%252Finference-service/artifacts'

      try {
        const response = await axios.get(baseUrl + uri, {
          params: {
            'page': '1',
            'page_size': '50',
            'with_tag': 'true',
            'with_label': 'false',
            'with_scan_overview': 'false',
            'with_signature': 'false',
            'with_immutable_status': 'false'
          },
          httpAgent: {
            'rejectUnauthorized': 'false',
          },
          headers: {
            'accept': 'application/json',
          }
        });

        let versions = response.data.map((dataEl, idx) => {
          return { value: dataEl.tags[0].name, label: dataEl.tags[0].name }
        })

        dispatch.inference.setBackendVersion(versions)
      } catch (error) {

      }
      //const { data } = await requestProtoGet(`/inference/backends`)
      //const response = Inference.deserializeBinary(data).toObject()
      //await dispatch.inference.setBackendVersion(.concat(response.versions))
      //dispatch.inference.get
    },
    /**
     * Reqeust get My Service list
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.name Service name
     * @param {String} payload.modelId Model Id
     * @param {Number} payload.instanceCount The number of Instance counts to use
     * @param {Object} payload.restriction Restriction Options
     * @param {String} payload.deviceType Type of device to use
     * @param {Nubmer} payload.gpuCount The number of Gpu counts to use
     * @property {request} request
     * @property {query} request.query /infernece/my
     * @property {type} request.type POST
     * @return {Inference} Response created Service info
     */
    async create(payload) {
      const restriction = new Restriction()
      const {
        name,
        modelId,
        instanceCount,
        priority,
        maxReplica,
        gpuCount,
        deviceType,
        backendVersion,
        restriction: restrictions
      } = payload
      if (Array.isArray(restrictions.ip)) {
        restrictions.ip.forEach(({ address }) => {
          const ipRestriction = new Restriction.Ip()
          ipRestriction.setAddress(address)
          restriction.addIp(ipRestriction)
        })
      }
      if (Array.isArray(restrictions.android)) {
        restrictions.android.forEach(({ packageName, sha1 }) => {
          const androidRestriction = new Restriction.Android()
          androidRestriction.setPackageName(packageName)
          androidRestriction.setSha1(sha1)
          restriction.addAndroid(androidRestriction)
        })
      }
      if (Array.isArray(restrictions.ios)) {
        restrictions.ios.forEach(({ bundleName }) => {
          const iosRestriction = new Restriction.Ios()
          iosRestriction.setBundleName(bundleName)
          restriction.addIos(iosRestriction)
        })
      }
      const createInferenceRequest = new CreateInferenceRequest()
      createInferenceRequest.setName(name)
      createInferenceRequest.setModelId(modelId)
      createInferenceRequest.setReplica(instanceCount)
      createInferenceRequest.setMaxReplica(maxReplica.value)
      createInferenceRequest.setPriority(priority)
      createInferenceRequest.setRestriction(restriction)
      createInferenceRequest.setDeviceType(deviceType)
      createInferenceRequest.setGpuCount(gpuCount)
      createInferenceRequest.setBackendVersion(backendVersion.value)
      const config = {
        data: createInferenceRequest.serializeBinary()
      }
      const { data } = await requestProtoPost("inference/my", config)
      const response = Inference.deserializeBinary(data).toObject()
      return response.id
    },
    /**
     * Reqeust get Service info
     * @memberof models.inference
     * @param {Object} payload - Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}
     * @property {type} request.type GET
     * @return {Inference} Response Inference Info
     */
    async getInfo(payload) {
      const { data } = await requestProtoGet(`/inference/${payload}`)
      const response = Inference.deserializeBinary(data).toObject()
      response.status = getStatusNameByValue[response.status]
      response.activated = response.status !== "STOP"
      return response
    },
    async addFavorite(payload, state) {
      try {
        const { status } = await requestProtoPost(
          `/inference/${payload}/favorite`
        )
        const { list } = state.inference
        // eslint-disable-next-line prefer-const
        let found = list.find(ele => ele.id === payload)
        found = {
          ...found,
          favorite: true
        }
        const updated = list.map(obj => (found.id === obj.id ? found : obj))
        await dispatch.inference.setList(updated)
        return status
      } catch (error) {
        return error.response.status
      }
    },
    async deleteFavorite(payload, state) {
      try {
        const { status } = await requestProtoDelete(
          `/inference/${payload}/favorite`
        )
        const { list } = state.inference
        // eslint-disable-next-line prefer-const
        let found = list.find(ele => ele.id === payload)
        found = {
          ...found,
          favorite: false
        }
        const updated = list.map(obj => (found.id === obj.id ? found : obj))
        await dispatch.inference.setList(updated)
        return status
      } catch (error) {
        return error.response.status
      }
    },
    /**
     * Reqeust delete Service
     * @memberof models.inference
     * @param {Object} payload - Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}
     * @property {type} request.type DELETE
     */
    async deleteService(payload) {
      const { status } = await requestProtoDelete(`/inference/${payload}`)
      if (status !== 200) {
        return {
          status: false,
          msg: "Fail delete service."
        }
      }
      return {
        status: true,
        msg: "Delete service successfully."
      }
    },
    /**
     * Reqeust activate Service
     * @memberof models.inference
     * @param {Object} payload - Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}/activate
     * @property {type} request.type PUT
     */
    async requestActivate(payload) {
      await requestProtoPut(`/inference/${payload}/activate`)
    },
    /**
     * Reqeust deactivate Service
     * @memberof models.inference
     * @param {Object} payload - Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}/deactivate
     * @property {type} request.type PUT
     */
    async requestDeactivate(payload) {
      await requestProtoPut(`/inference/${payload}/deactivate`)
    },
    /**
     * Reqeust get Service State
     * @memberof models.inference
     * @param {Object} payload - Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}/status
     * @property {type} request.type GET
     */
    async getStatus(payload) {
      const { data } = await requestProtoGet(`/inference/${payload}/status`)
      const serverStatus = ServerStatus.deserializeBinary(data)
      const modelStatus = serverStatus.getModelStatusMap()
      // eslint-disable-next-line no-underscore-dangle
      const modelStatusObject = modelStatus.stringKeys_().reduce((acc, key) => {
        const versionStatus = modelStatus.get(key).getVersionStatusMap()
        // eslint-disable-next-line no-underscore-dangle
        const VSMObject = versionStatus.stringKeys_().reduce((acc2, key2) => {
          return {
            ...acc2,
            [key2]: versionStatus.get(key2).toObject()
          }
        }, {})
        modelStatus.get(key).clearVersionStatusMap()
        return {
          ...acc,
          [key]: {
            ...modelStatus.get(key).toObject(),
            versionStatus: VSMObject
          }
        }
      }, {})
      Object.keys(modelStatusObject).forEach(key => {
        modelStatusObject[key].config.inputList.forEach(v => {
          v.dataType = getDataTypeNameByValue[v.dataType]
        })
        modelStatusObject[key].config.outputList.forEach(v => {
          v.dataType = getDataTypeNameByValue[v.dataType]
        })
      })
      serverStatus.clearModelStatusMap()
      const response = {
        ...serverStatus.toObject(),
        modelStatus: modelStatusObject
      }
      return response
    },
    /**
     * Service share to User
     * @memberof models.inference
     * @param {Object} payload
     * @param {Object} payload.restriction
     * @param {String} payload.inferenceId
     * @param {Array} payload.targetUsers
     * @property {request} request
     * @property {query} request.query /infernece/${inferenceId}/share/${user}
     * @property {type} request.type PUT
     * @return {Array} Successed Service Shared list
     */
    async shareService(payload) {
      const restriction = new Restriction()
      if (Array.isArray(payload.restriction.ip)) {
        payload.restriction.ip.forEach(({ address }) => {
          const ipRestriction = new Restriction.Ip()
          ipRestriction.setAddress(address)
          restriction.addIp(ipRestriction)
        })
      }
      if (Array.isArray(payload.restriction.android)) {
        payload.restriction.android.forEach(({ packageName, sha1 }) => {
          const androidRestriction = new Restriction.Android()
          androidRestriction.setPackageName(packageName)
          androidRestriction.setSha1(sha1)
          restriction.addAndroid(androidRestriction)
        })
      }
      if (Array.isArray(payload.restriction.ios)) {
        payload.restriction.ios.forEach(({ bundleName }) => {
          const iosRestriction = new Restriction.Ios()
          iosRestriction.setBundleName(bundleName)
          restriction.addIos(iosRestriction)
        })
      }
      const shareInferenceRequest = new ShareInferenceRequest()
      shareInferenceRequest.setRestriction(restriction)
      const config = {
        data: shareInferenceRequest.serializeBinary()
      }
      const { inferenceId, targetUsers } = payload
      const successList = []
      const failList = []
      // eslint-disable-next-line no-restricted-syntax
      const requestInferenceShare = async user => {
        try {
          const response = await requestProtoPut(
            `/inference/${inferenceId}/share/${user}`,
            config
          )
          successList.push({
            userId: user,
            status: response.status
          })
        } catch (error) {
          failList.push({
            userId: user,
            status: error.response.status
          })
        }
      }

      for (const user of targetUsers) {
        // eslint-disable-next-line no-await-in-loop
        await requestInferenceShare(user)
      }
      const result = {
        success: successList,
        fail: failList
      }
      return result
    },
    /**
     * Service unshare to User
     * @memberof models.inference
     * @param {Object} payload Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}/unshare
     * @property {type} request.type PUT
     * @return {Number} Response HttpStatusCode
     */
    async unshareService(payload) {
      try {
        const { status } = await requestProtoPut(
          `/inference/${payload}/unshare`
        )
        return status
      } catch (error) {
        return null
      }
    },
    /**
     * Request get Shared List
     * @memberof models.inference
     * @param {String} payload
     * @property {request} request
     * @property {query} request.query /infernece/${payload}/share/list
     * @property {type} request.type GET
     * @return {Array} Response Shared service list
     */
    async getSharedList(payload) {
      const { data } = await requestProtoGet(`/inference/${payload}/share/list`)
      const response = ListInferenceResponse.deserializeBinary(data).toObject()
      return response.inferencesList
    },
    /**
     * Reqeust shared list using getSharedList
     * @memberof models.inference
     * @param {String} payload
     */
    async requestSharedList(payload) {
      const sharedList = await this.getSharedList(payload)
      const formatted = sharedList.map(value => ({
        id: value.id,
        userId: value.userId,
        created: dateFormat(Number(value.created), "YYYY/MM/DD"),
        token: value.tokensList
      }))
      this.setSharedList(formatted)
    },
    /**
     * Request get Instances
     * @memberof models.inference
     * @param {Object} payload Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${payload}/instances
     * @property {type} request.type GET
     * @return {Array} Instance list
     */
    async getInstances(payload) {
      const { data } = await requestProtoGet(`/inference/${payload}/instances`)
      const response = ListInstanceResponse.deserializeBinary(data).toObject()
      return response.instancesList
    },
    /**
     * Request get service token
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.inferenceId Service Id
     * @property {request} request
     * @property {query} request.query /infernece/${inferenceId}/token
     * @property {type} request.type GET
     * @return {Array} Service Token
     */
    async getToken(payload) {
      const { inferenceId } = payload
      try {
        const { data } = await requestProtoGet(
          `/inference/${inferenceId}/token`
        )
        const response = Token.deserializeBinary(data).toObject()
        return response
      } catch (error) {
        return null
      }
    },
    // async updateToken(payload) {
    //   const restriction = new Restriction()
    //   if (Array.isArray(payload.restriction.ip)) {
    //     payload.restriction.ip.forEach(({ address }) => {
    //       const ipRestriction = new Restriction.Ip()
    //       ipRestriction.setAddress(address)
    //       restriction.addIp(ipRestriction)
    //     })
    //   }
    //   if (Array.isArray(payload.restriction.android)) {
    //     payload.restriction.android.forEach(({ packageName, sha1 }) => {
    //       const androidRestriction = new Restriction.Android()
    //       androidRestriction.setPackageName(packageName)
    //       androidRestriction.setSha1(sha1)
    //       restriction.addAndroid(androidRestriction)
    //     })
    //   }
    //   if (Array.isArray(payload.restriction.ios)) {
    //     payload.restriction.ios.forEach(({ bundleName }) => {
    //       const iosRestriction = new Restriction.Ios()
    //       iosRestriction.setBundleName(bundleName)
    //       restriction.addIos(iosRestriction)
    //     })
    //   }
    //   const updateTokenRequest = new UpdateTokenRequest()
    //   updateTokenRequest.setRestriction(restriction)
    //   const config = {
    //     data: updateTokenRequest.serializeBinary()
    //   }
    //   const id = payload.sharedServiceId
    //   const { status } = await requestProtoPut(`/inference/${id}/token`, config)
    //   return status
    // },
    /**
     * Request get Service log
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.id Service id
     * @param {String} payload.instanceId Service instance id
     * @param {Number} payload.from Log start time
     * @param {Number} payload.to Log end time
     * @param {Number} payload.pageSize Size of Page Rows
     * @param {Number} payload.pageNumber Page Number
     * @property {request} request
     * @property {query} request.query /log/${id}/${instanceId}
     * @property {type} request.type GET
     * @return {ListLogResponse} ListLogResponse
     */
    async getLog(payload) {
      const { id, instanceId, pageNumber } = payload
      try {
        if (id === instanceId) {
          const { data } = await requestProtoGet(
            `/log/${id}?page=${pageNumber}`
          )
          const response = ListLogResponse.deserializeBinary(data).toObject()
          return response
        }
        const { data } = await requestProtoGet(
          `/log/${id}/${instanceId}?&page=${pageNumber}`
        )
        const response = ListLogResponse.deserializeBinary(data).toObject()
        return response
      } catch (error) {
        return {
          paging: {
            pageNumber: 0,
            pageSize: 20,
            totalCount: 0
          }
        }
      }
    },
    /**
     * Request get list statistics
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.inferenceId Service id
     * @param {Number} payload.step step size
     * @param {Number} payload.from Log start time
     * @param {Number} payload.to Log end time
     * @property {request} request
     * @property {query} request.query /inference/${inferneceId}/statistics
     * @property {type} request.type GET
     * @return {ListStatisticsResponse} ListStatisticsResponse
     */
    async getListStatistics(payload) {
      const { inferenceId, step, from, to } = payload
      try {
        const { data } = await requestProtoGet(
          `/inference/${inferenceId}/statistics?from=${from}&to=${to}&step=${step}`,
          {}
        )
        const response = ListStatisticsResponse.deserializeBinary(
          data
        ).toObject()
        return response
      } catch (error) {
        return undefined
      }
    },
    /**
     * Request get service token list
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.inferenceId Service id
     * @property {request} request
     * @property {query} request.query /inference/${inferenceId}/token/list
     * @property {type} request.type GET
     * @return {ListTokenResponse} ListTokenResponse
     */
    async getTokenList(payload) {
      const { inferenceId } = payload
      const { data } = await requestProtoGet(
        `/inference/${inferenceId}/token/list`
      )
      const response = ListTokenResponse.deserializeBinary(data).toObject()
      return response
    },
    /**
     * Request add service token
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.inferenceId Service id
     * @param {Object} payload.restriction Service restrictions
     * @property {request} request
     * @property {query} request.query /inference/${inferenceId}/token
     * @property {type} request.type POST
     */
    async addToken(payload) {
      const { inferenceId, restriction } = payload
      const restrictionProto = makeRestrictionProtoMessage(restriction)
      const updateTokenRequest = new UpdateTokenRequest()
      updateTokenRequest.setRestriction(restrictionProto)
      await requestProtoPost(`/inference/${inferenceId}/token`, {
        data: updateTokenRequest.serializeBinary()
      })
      return null
    },
    async downloadLogFile(payload) {
      const { id, instanceId, pageIdx } = payload
      const { data } = await requestProtoGet(
        `/log/${id}/${instanceId}/download?page=${pageIdx}`
      )
      const { token } = DownloadToken.deserializeBinary(data).toObject()
      return token
    },
    /**
     * Request update service token
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.inferenceId Service id
     * @param {String} payload.token Service token
     * @param {Object} payload.restriction Service restrictions
     * @property {request} request
     * @property {query} request.query /inference/${inferenceId}/token
     * @property {type} request.type PUT
     */
    async updateToken(payload) {
      const { inferenceId, token } = payload
      await requestProtoPut(`/inference/${inferenceId}/token/${token}`, {})
      return null
    },
    /**
     * Request delete service token
     * @memberof models.inference
     * @param {Object} payload
     * @param {String} payload.inferenceId Service id
     * @param {String} payload.token Service token
     * @property {request} request
     * @property {query} request.query /inference/${inferenceId}/token/${token}
     * @property {type} request.type DELETE
     */
    async deleteToken(payload) {
      const { inferenceId, token } = payload
      const { data } = await requestProtoDelete(
        `/inference/${inferenceId}/token/${token}`
      )
      try {
        const { status } = Token.deserializeBinary(data).toObject()
        return status
      } catch (error) {
        return null
      }
    }
  })
}

export default inference
