/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Dropdown } from "react-bootstrap"

const DropDownItem = ({ key, label, onSelect }) => {
  return (
    <Dropdown.Item key={key} className="btn-ghost-light" onSelect={onSelect}>
      {label}
    </Dropdown.Item>
  )
}

const DropDownComponent = ({
  label,
  menus,
  onSelectMenuItem,
  as,
  onSearchChange = () => {},
  onToggleClick = () => {}
}) => {
  const menuProps = () => {
    return as ? { as, onSearchChange } : {}
  }

  return (
    <Dropdown className="d-flex" onClick={() => onToggleClick()}>
      <Dropdown.Toggle variant="ghost-dark" className="border-0 shadow-none">
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu
        {...menuProps}
        style={{ maxHeight: "200px", overflow: "auto" }}
      >
        {menus.map(item => (
          <DropDownItem
            key={`dropdown-item-${item.value}`}
            label={item.label}
            onSelect={() => onSelectMenuItem(item)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropDownComponent
