/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable no-param-reassign */

/**
 * @namespace resource
 * @memberof models
 */
import produce from "immer"
import {
  ListNodeResponse,
  ListResourceResponse,
  InferenceStatus,
  ServiceStatus,
  ListInferenceCount
} from "arti-proto"
import { requestProtoGet } from "./api"

/**
 * @memberof models.resource
 * @const
 * @type {Object}
 * @property {Array} gpus Gpu List
 */
const initialState = {
  resources: [],
  serviceStatus: {
    failedInstanceCount: 0,
    penddingInstanceCount: 0,
    runningInstanceCount: 0,
    totalServiceCount: 0,
    totalModelCount: 0,
    usedGpuCount: 0,
    usedSapeonCount: 0
  },
  inferenceStatus: {
    totalInferenceCount: 0,
    totalInferenceCountsList: [],
    top5InferenceCountsList: [],
    sapeonUtilizationsList: [],
    gpuUtilizationsList: [],
    inferenceDurationsList: [],
    inferCountStep: 1,
    userInferCountStep: 1,
    userInferenceCountsList: []
  },
  userInferenceCount: {
    inferenceCountsList: [],
    userInferCountStep: 1
  },
  sapeonX220TotalResources: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  sapeonX330TotalResources: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  gpuTotalResources: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  sapeonX220TotalResourceQuota: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  sapeonX330TotalResourceQuota: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  gpuTotalResourceQuota: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  }
}

const NVIDIA_GPU_DEVICE_TYPE = "nvidia.com/gpu"
const SAPEON_X220_DEVICE_TYPE = "skt.com/aix_v1"
const SAPEON_X330_DEVICE_TYPE = "sapeon.com/snx3"

const resource = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.resource
     * @param {State} state - Current State
     * @param {Object} payload - Gpu List
     * @return {State} - Changed State
     */
    setResources(state, payload) {
      return produce(state, draft => {
        draft.resources = payload
      })
    },
    setServiceStatus(state, payload) {
      const totalInstanceCount =
        payload.failedInstanceCount +
        payload.penddingInstanceCount +
        payload.runningInstanceCount
      payload.totalInstanceCount = totalInstanceCount
      return produce(state, draft => {
        draft.serviceStatus = payload
      })
    },
    setInferenceStatus(state, payload) {
      // let totalInferenceCount = 0
      // payload.totalInferenceCountsList.forEach(
      //   // eslint-disable-next-line no-return-assign
      //   countEle => (totalInferenceCount += countEle.totalCount)
      // )
      // payload.totalInferenceCount = totalInferenceCount
      return produce(state, draft => {
        draft.inferenceStatus = payload
      })
    },
    setUserInferenceCount(state, payload) {
      return produce(state, draft => {
        draft.userInferenceCount = payload
      })
    },
    setSapeonX220TotalResources(state, payload) {
      return produce(state, draft => {
        draft.sapeonX220TotalResources = payload
      })
    },
    setSapeonX330TotalResources(state, payload) {
      return produce(state, draft => {
        draft.sapeonX330TotalResources = payload
      })
    },
    setGpuTotalResources(state, payload) {
      return produce(state, draft => {
        draft.gpuTotalResources = payload
      })
    },
    setSapeonX220TotalResourceQuota(state, payload) {
      return produce(state, draft => {
        draft.sapeonX220TotalResourceQuota = payload
      })
    },
    setSapeonX330TotalResourceQuota(state, payload) {
      return produce(state, draft => {
        draft.sapeonX330TotalResourceQuota = payload
      })
    },
    setGpuTotalResourceQuota(state, payload) {
      return produce(state, draft => {
        draft.gpuTotalResourceQuota = payload
      })
    },
  },
  effects: dispatch => ({
    /**
     * Request get node list
     * @memberof models.resource
     * @function
     * @property {request} request
     * @property {query} request.query /resource/nodes
     * @property {type} request.type GET
     * @return {ListNodeResponse} ListNodeResponse
     */
    async getNodes() {
      const { data } = await requestProtoGet("resource/nodes")
      const response = ListNodeResponse.deserializeBinary(data).toObject()
      return response
    },
    /**
     * Request get resource list
     * @memberof models.resource
     * @function
     * @property {request} request
     * @property {query} request.query /resource/
     * @property {type} request.type GET
     * @return {ListResourceResponse} ListResourceResponse
     */
    async getResources() {
      const { data } = await requestProtoGet("resource/")
      const response = ListResourceResponse.deserializeBinary(
        data
      ).toObject()
      const deviceTypes = response.resourcesList
        .filter(deviceType => deviceType.capacity > 0)
        .sort((a, b) => (a.value > b.value) ? 1 : -1)
        .map(deviceType => {
          return {
            value: deviceType.deviceType,
            label: `${deviceType.deviceType.split("/").length > 1
              ? deviceType.deviceType.split("/")[1]
              : deviceType.deviceType
              } `,
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable
          }
        })
      deviceTypes.forEach(async (deviceType) => {
        if (deviceType.value === NVIDIA_GPU_DEVICE_TYPE) {
          await dispatch.resource.setGpuTotalResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === SAPEON_X220_DEVICE_TYPE) {
          await dispatch.resource.setSapeonX220TotalResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === SAPEON_X330_DEVICE_TYPE) {
          await dispatch.resource.setSapeonX330TotalResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        }
      })
      await dispatch.resource.setResources(deviceTypes)
      return deviceTypes
    },
    /**
     * Request get resource quota 
     * @memberof models.resource
     * @function
     * @property {request} request
     * @property {query} request.query /resource/quota
     * @property {type} request.type GET
     * @return {ListResourceResponse} ListResourceResponse
     */
    async getResourcesQuotas() {
      const { data } = await requestProtoGet("resource/quota")
      const response = ListResourceResponse.deserializeBinary(
        data
      ).toObject()
      const deviceTypes = response.resourcesList.map(deviceType => {
        return {
          value: deviceType.deviceType,
          label: `${deviceType.deviceType.split("/").length > 1
            ? deviceType.deviceType.split("/")[1]
            : deviceType.deviceType
            } `,
          capacity: deviceType.capacity,
          allocatable: deviceType.allocatable
        }
      })
      deviceTypes.forEach(async (deviceType) => {
        if (deviceType.value === NVIDIA_GPU_DEVICE_TYPE) {
          await dispatch.resource.setGpuTotalResourceQuota({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === SAPEON_X220_DEVICE_TYPE) {
          await dispatch.resource.setSapeonX220TotalResourceQuota({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === SAPEON_X330_DEVICE_TYPE) {
          await dispatch.resource.setSapeonX330TotalResourceQuota({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        }
      })
    },
    // FIXME : Modify resource to groups
    /*
    async getGroupResources(groupname) { 
      const { data } = await requestProtoGet(`/group/${groupname}/resource`)
      const response = ListResourceResponse.deserializeBinary(data).toObject()
      const deviceTypes = response.resourcesList.map(deviceType => {
        return {
          value: deviceType.deviceType,
          label: `${deviceType.deviceType.split("/").length > 1
            ? deviceType.deviceType.split("/")[1]
            : deviceType.deviceType
            } `,
          capacity: deviceType.capacity,
          allocatable: deviceType.allocatable
        }
      })
      deviceTypes.forEach(async (deviceType) => {
        if (deviceType.value === "nvidia.com/gpu") {
          await dispatch.groups.setGpuGroupResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === "skt.com/aix_v1") {
          await dispatch.groups.setSapeonX220GroupResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === "sapeon.com/snx3") {
          await dispatch.groups.setSapeonX330GroupResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        }
      })
      await dispatch.resource.setResources(deviceTypes)
    },*/

    async getServiceStatus(payload) {
      const { userId } = payload
      const { data } = await requestProtoGet(
        `resource/dashboard/servicestatus?userId=${userId}`
      )
      const response = ServiceStatus.deserializeBinary(data).toObject()
      await dispatch.resource.setServiceStatus(response)
    },

    async getInferenceStatus(payload) {
      const { timeLabel, serviceId, fromInfer, toInfer, userId, namespace } = payload
      const toTime = new Date()
      const tempTime = new Date(toTime.getTime())
      const fromTime = new Date(tempTime.setMinutes(tempTime.getMinutes() - 60))

      // eslint-disable-next-line prefer-const
      let toInferTime = Number(toInfer) + 86399000 // 23:59:59
      if (new Date().getTime() <= toInferTime) {
        // calc today
        // 32400000 : 9h
        toInferTime = new Date().getTime()
      }

      // eslint-disable-next-line prefer-const
      let fromInferTime = Number(fromInfer)

      const query = `resource/dashboard/inferencestatus?from=${Number(
        fromTime
      )}&to=${Number(toTime)}&step=60&totalCountFrom=${Number(
        fromInferTime
      )}&totalCountTo=${toInferTime}&inferCountStep=${timeLabel.value
        }&userId=${userId}${serviceId === 0 ? "" : `&serviceId=${serviceId}`}${(namespace === undefined || namespace === null) ? "" : `&namespace=${namespace}`}`
      const { data } = await requestProtoGet(query)

      const between = Math.round(
        (toInferTime - fromInferTime) / (1000 * 3600 * 24)
      )

      const response = InferenceStatus.deserializeBinary(data).toObject()
      if (between > 213) {
        response.inferCountStep = 3
      } else if (between > 49) {
        response.inferCountStep = 2
      } else if (between > 7) {
        response.inferCountStep = 1
      } else {
        response.inferCountStep = 0
      }
      await dispatch.resource.setInferenceStatus(response)
    },
    async getListUserInferenceCount(payload) {
      const { from, to, userId } = payload
      // eslint-disable-next-line prefer-const
      let toTime = Number(to) + 86399000 // 23:59:59
      if (new Date().getTime() <= toTime) {
        // calc today
        // 32400000 : 9h
        toTime = new Date().getTime()
      }
      // eslint-disable-next-line prefer-const
      let fromTime = Number(from)
      const { data } = await requestProtoGet(
        `resource/dashboard/userinferencecount?userId=${userId}&from=${fromTime}&to=${toTime}`
      )
      const response = ListInferenceCount.deserializeBinary(data).toObject()

      const userBetween = Math.round((to - from) / (1000 * 3600 * 24))
      if (userBetween > 213) {
        response.userInferCountStep = 3
      } else if (userBetween > 49) {
        response.userInferCountStep = 2
      } else if (userBetween > 7) {
        response.userInferCountStep = 1
      } else {
        response.userInferCountStep = 0
      }
      await dispatch.resource.setUserInferenceCount(response)
    },

    async downloadTotalCountCsvFile(payload) {
      const { csv, filename } = payload
      const BOM = "\uFEFF"
      const csvFile = new Blob([`${BOM}${csv}`], { type: "text/csv" })
      const downloadLink = document.createElement("a")
      downloadLink.download = filename
      downloadLink.href = window.URL.createObjectURL(csvFile)
      downloadLink.style.display = "none"
      document.body.appendChild(downloadLink)
      downloadLink.click()
    }
  })
}

export default resource
