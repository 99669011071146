/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from "react"
import { Button, Carousel, Col, ListGroup, Row } from "react-bootstrap"

const fixmeFunc = (arr, i) => {
  if (i === 0) return "border-0 pl-0 pt-0 pb-2"
  if (i === arr.length - 1) return "pl-0 pt-2 pb-5"
  return "pl-0 py-2"
}

const convertToString = target => {
  if (typeof target === "object") {
    return JSON.stringify(target, null, 2)
  }
  return String(target)
}
const inputStyle = {
  borderRadius: "0.25rem",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#e5e5ea"
}
const ConfigInputs = props => {
  const { data, fontSize } = props
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)
  const maxIndex = data.inputList.length - 1
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }
  const handlePrev = () => {
    if (index > 0) {
      setIndex(prev => prev - 1)
      return
    }
    if (index === 0) {
      setIndex(maxIndex)
    }
  }
  const handleNext = () => {
    if (index < maxIndex) {
      setIndex(prev => prev + 1)
      return
    }
    if (index === maxIndex) {
      setIndex(0)
    }
  }

  return (
    <div className="d-flex mt-5 mt-md-0">
      <Carousel
        className="input-list-caroucel col-11 border-0"
        style={{...inputStyle,fontSize}}
        activeIndex={index}
        direction={direction}
        onSelect={handleSelect}
        prevIcon={
          <Button
            className="p-0"
            variant="btn btn-ghost-light icon-only"
            onClick={() => handlePrev()}
          >
            <span className="btn-carousel-icon glyphicons-uxs_previous" />
          </Button>
        }
        nextIcon={
          <Button
            className="p-0"
            variant="btn btn-ghost-light icon-only"
            onClick={() => handleNext()}
          >
            <span className="btn-carousel-icon glyphicons-uxs_next" />
          </Button>
        }
        interval={null}
      >
        {!!data &&
          data.inputList.map((element, idx) => {
            return (
              <Carousel.Item key={idx}>
                <ListGroup border="none" variant="flush" />
                {Object.keys.length > 0 &&
                  Object.keys(element).map((key, i) => {
                    if (key === "format") {
                      return null
                    }
                    return (
                      <ListGroup.Item
                        key={`${element.name}-input-${key}`}
                        className={fixmeFunc(Object.keys(element), i)}
                      >
                        <Row>
                          <Col className="font-weight-bold col-4">{key}</Col>
                          <Col>{convertToString(element[key])}</Col>
                        </Row>
                      </ListGroup.Item>
                    )
                  })}
              </Carousel.Item>
            )
          })}
      </Carousel>
    </div>
  )
}

export default ConfigInputs
