/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

/**
 * @memberof components
 * @property {Component} children - Children in Dropzone
 * @property {String} className - Set Dropzone className
 * @property {Function} onFileChange - Execute a function when changed file states
 * @property {Number} progress - Uploading progress state
 * @property {Boolean} disabled - If disabled is true, deactivate Dropzone
 */
const Dropzone = ({
  className,
  onFileChange,
  children,
  progress,
  disabled
}) => {
  const fileInputRef = useRef()
  const [highlight, setHighlight] = useState(false)

  const openFileDialog = () => {
    if (!fileInputRef.current) return
    fileInputRef.current.click()
  }

  const handleDragOver = e => {
    e.preventDefault()
    if (disabled) return
    setHighlight(true)
  }

  const handleDragLeave = () => {
    setHighlight(false)
  }

  const handleDrop = e => {
    e.preventDefault()
    setHighlight(false)
    if (disabled) return
    const { files } = e.dataTransfer
    onFileChange(Array.from(files))
  }

  const handleFileChange = e => {
    if (disabled) return
    const { files } = e.target
    onFileChange(Array.from(files))
  }

  if (progress >= 100) {
    return (
      <div className="d-flex align-items-center">
        <div className="dropzone-circular-progressbar-container ml-2 mt-2">
          <CircularProgressbar
            value={progress}
            maxValue={100}
            text={`${progress} %`}
          />
        </div>
        <span className="ml-3">Processing file</span>
      </div>
    )
  }

  if (progress >= 0) {
    return (
      <div className="d-flex align-items-center">
        <div className="dropzone-circular-progressbar-container ml-2 mt-2">
          <CircularProgressbar
            value={progress}
            maxValue={100}
            text={`${progress} %`}
          />
        </div>
        <span className="ml-3">Uploading file</span>
      </div>
    )
  }

  return (
    <button
      type="button"
      className={classnames(className, "dropzone", "dropzone-model", {
        "dropzone-is-over": highlight
      })}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={openFileDialog}
      disabled={disabled}
    >
      <input
        ref={fileInputRef}
        type="file"
        className="d-none"
        onChange={handleFileChange}
      />
      {children}
    </button>
  )
}

export default Dropzone

Dropzone.defaultProps = {
  className: "",
  onFileChange: () => {}
}

Dropzone.propTypes = {
  className: PropTypes.string,
  onFileChange: PropTypes.func
}
