/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useEffect } from "react"

const ToggleSwitch = ({
  className,
  onChange,
  checked,
  defaultChecked = true
}) => {
  const [active, setActive] = useState(defaultChecked)

  const propsIsNotSet = key => {
    return key === undefined || key === null
  }

  const handleClick = e => {
    e.stopPropagation()
    if (propsIsNotSet(checked)) {
      setActive(!active)
    }
  }

  useEffect(() => {
    if (!propsIsNotSet(checked)) {
      setActive(checked)
    }
  }, [checked])

  const transparentButtonStyle = {
    backgroundColor: "transparent",
    backgorundRepeat: "no-repeat",
    border: "none",
    overflow: "hidden",
    outline: "none",
    width: "2.5rem",
    height: "1.5rem",
    display: "flex"
  }

  const toggleSwitchPillStyle = {
    width: "2.5rem",
    height: "1.5rem",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: active ? "#5267f6" : "#8e8e93",
    transition: "background-color 0.15s ease-in-out"
  }

  const toggleSwitchCircleStyle = {
    width: "1.375rem",
    height: "1.375rem",
    position: "absolute",
    top: "0.0625rem",
    left: "1px",
    transform: !active ? "translateX(1rem)" : "",
    transition: "transform 0.15s ease-in-out"
  }

  return (
    <div className={className}>
      <button
        type="button"
        onClick={onChange || handleClick}
        style={transparentButtonStyle}
        className="position-relative"
      >
        <div className="rounded-pill" style={toggleSwitchPillStyle} />
        <div
          className="rounded-circle bg-white"
          style={toggleSwitchCircleStyle}
        />
      </button>
    </div>
  )
}

export default ToggleSwitch
