/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @module utils
 */

import { isSameWeek } from "date-fns"
import { isSameDay, startOfWeek } from "date-fns/esm"

/**
 * @export utils
 * @param   {string} message  alert popup message
 */
export const addAlert = (message, timeout = 3000) => {
  const setAlert = () => {
    document.getElementById("toast-action-alert-message").innerText = message
    document.getElementById("toast-action-alert").style.display = "flex"
    setTimeout(() => {
      document.getElementById("toast-action-alert-message").innerText = null
      document.getElementById("toast-action-alert").style.display = "none"
    }, timeout)
  }
  setAlert()
}

/**
 * @export utils
 * @param {Object} modelConfig - Model Config
 * @return {Array} - ModelConfig Converted MermaidConfig
 */
export function convertModelConfigToMermaidFlowChart(modelConfig) {
  const inoutStore = {}
  const flowchartCodeList = ["graph TB"]
  const transparentStyle = "stroke:transparent, fill:transparent;"
  flowchartCodeList.push(`style ensemble-input-outline ${transparentStyle}`)
  flowchartCodeList.push(`style ensemble-input-inline ${transparentStyle}`)
  //flowchartCodeList.push(`style ensemble-input ${transparentStyle}`)
  //flowchartCodeList.push("subgraph ensemble-input[model-input]")
  flowchartCodeList.push("subgraph ensemble-input-outline[ ]")
  flowchartCodeList.push("subgraph ensemble-input-inline[ ]")
  modelConfig.input.forEach((value, idx) => {
    inoutStore[value.name] = `ensemble-input-${idx}`
    flowchartCodeList.push(`ensemble-input-${idx}[${value.name}]`)
  })
  //flowchartCodeList.push("end")
  flowchartCodeList.push("end")
  flowchartCodeList.push("end")
  modelConfig.ensembleScheduling.step.forEach((step, idx) => {
    flowchartCodeList.push(`style step-${idx}-outline ${transparentStyle}`)
    flowchartCodeList.push(`style step-${idx}-inline ${transparentStyle}`)
    flowchartCodeList.push(`subgraph step-${idx}[${step.modelName}]`)
    flowchartCodeList.push(`subgraph step-${idx}-outline[ ]`)
    flowchartCodeList.push(`subgraph step-${idx}-inline[ ]`)
    Object.entries(step.inputMap).forEach(([key, value]) => {
      flowchartCodeList.push(`step-${idx}-${key}[${key}]`)
      flowchartCodeList.push(`${inoutStore[value]} --> step-${idx}-${key}`)
      inoutStore[value] = undefined
    })
    Object.entries(step.outputMap).forEach(([key, value]) => {
      inoutStore[value] = `step-${idx}-${key}`
      flowchartCodeList.push(`step-${idx}-${key}{{${key}}}`)
    })
    flowchartCodeList.push("end")
    flowchartCodeList.push("end")
    flowchartCodeList.push("end")
  })
  flowchartCodeList.push(`style ensemble-output-outline ${transparentStyle}`)
  flowchartCodeList.push(`style ensemble-output-inline ${transparentStyle}`)
  //flowchartCodeList.push(`style model-output ${transparentStyle}`)
  //flowchartCodeList.push("subgraph model-output")
  flowchartCodeList.push("subgraph ensemble-output-outline[ ]")
  flowchartCodeList.push("subgraph ensemble-output-inline[ ]")
  modelConfig.output.forEach((value, idx) => {
    flowchartCodeList.push(`ensemble-output-${idx}{{${value.name}}}`)
    flowchartCodeList.push(`${inoutStore[value.name]} --> ensemble-output-${idx}`)
    inoutStore[value.name] = undefined
  })
  //flowchartCodeList.push("end")
  flowchartCodeList.push("end")
  flowchartCodeList.push("end")
  return flowchartCodeList.join("\n")
}

export function downloadFile(url, fileName) {
  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
}

/**
 * @export utils
 * @param   {number} length  string length
 * @return  {string} - random string
 */
export function getRandomString(length) {
  let result = ""
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

/**
 * @export utils
 * @param   {int} minNumber Minimum number
 * @param   {int} maxNumber Maximum number
 * @return  {int} - Random number
 */
export function getRandomInt(mn, mx) {
  const mnCeiled = Math.ceil(mn)
  const mxFloored = Math.floor(mx)
  return Math.floor(Math.random() * (mxFloored - mnCeiled)) + mnCeiled
}

/**
 * @export utils
 * @param   {string} date  Date value
 * @param   {string} formatString   YYYY (FullYear), MM (Month), DD (Date), HH (24 hours), hh(12 hours), mm(Minutes), ss (seconds) => example ("YYYY-MM-DD HH:mm:ss")
 * @return  {string} - Date String (2021-02-01 16:21:03)
 */
export function dateFormat(date, formatString) {
  const targetDate = new Date(date)
  const zf = str => {
    return String.prototype.padStart.call(str, 2, "0")
  }
  const hours = targetDate.getHours()
  return formatString
    .replace("YYYY", targetDate.getFullYear())
    .replace(
      "yy",
      targetDate
        .getFullYear()
        .toString()
        .substr(-2)
    )
    .replace("MM", zf(targetDate.getMonth() + 1))
    .replace("DD", zf(targetDate.getDate()))
    .replace("HH", zf(hours))
    .replace("hh", zf(hours > 12 ? hours - 12 : hours))
    .replace("mm", zf(targetDate.getMinutes()))
    .replace("ss", zf(targetDate.getSeconds()))
}

export function formatSizeUnits(bytes) {
  let changedUnit = `${0}`
  if (bytes >= 1073741824) {
    changedUnit = `${(bytes / 1073741824).toFixed(2)} G`
  } else if (bytes >= 1048576) {
    changedUnit = `${(bytes / 1048576).toFixed(2)} M`
  } else if (bytes >= 1024) {
    changedUnit = `${(bytes / 1024).toFixed(2)} K`
  }
  return changedUnit
}

/**
 * @export utils
 * @param   {number} fileSize Size of the file
 * @return  {string} - Converted file size
 */
export function convertFileSizeToString(size) {
  /**
   * default standard is bytes
   */
  if (size > 1000 * 1000 * 1000) {
    const buf = size / (1000 * 1000 * 1000)
    return `${buf.toFixed(1)} GB`
  }
  if (size > 1000 * 1000) {
    const buf = size / (1000 * 1000)
    return `${buf.toFixed(1)} MB`
  }
  if (size > 1000) {
    const buf = size / 1000
    return `${buf.toFixed(0)} KB`
  }
  return `${size} bytes`
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function invert(obj) {
  return Object.entries(obj).reduce((acc, entry) => {
    const [key, val] = entry
    acc[val] = key
    return acc
  }, {})
}

export function parseJWT(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}

/**
 * @export utils
 * @param   {event} keyEvent KeyEvent
 * @param   {string} elementId Next Field Id
 */
export function handleEnter(event, nextField) {
  if (event.keyCode === 13) {
    if (nextField) {
      document.getElementById(nextField).focus()
      event.preventDefault()
      return
    }
    const { form } = event.target
    const index = Array.prototype.indexOf.call(form, event.target)
    form.elements[index + 1].focus()
    event.preventDefault()
  }
}

/**
 * @export utils
 * @param {event} keyEvent - KeyEvent
 * @param {callback} callBack - Callback function
 */
export function handleTab(event, callBack = () => {}) {
  if (event.keyCode === 9) {
    const { value } = event.target
    const { selectionStart, selectionEnd } = event.target
    event.target.value = `${value.substring(
      0,
      selectionStart
    )}\t${value.substring(selectionEnd)}`
    event.target.selectionStart = selectionStart + 1
    event.target.selectionEnd = selectionEnd + 1
    callBack(event)
    event.preventDefault()
  }
}

/**
 * @export utils
 * @param   {Object} object Object value
 * @return  {Object} - Object found using key
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

export function checkValidPredefined(param) {
  const regex = new RegExp(/^[A-Za-z0-9-]{2,20}$/)
  return regex.test(param)
}

export function checkValidPath(param) {
  const regex = new RegExp(/^[a-z0-9-/]{2,30}$/)
  return regex.test(param)
}

export function makeElementId() {
  // eslint-disable-next-line no-var
  let text = ""
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

function betweenMin(start, end) {
  const date = new Date(end) - new Date(start)
  const minutes = Math.floor(date / 1000 / 60)
  return minutes
}

export function getBetweenDate(start, end) {
  if (isSameWeek(start, end)) {
    return 0
  }
  // startOfWeek(end)
  if (start < end) {
    const minutes = betweenMin(start, startOfWeek(end))
    return Math.ceil(minutes / 10080)
  }

  if (isSameDay(start, end)) {
    return 0
  }
}

// Generate random color
const getRandomColor=()=> {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// Create Image from initials
export function createImageFromInitials(size, username) {
  const color = getRandomColor();
  if (username == null) return;
  let initials;
  const usernameLength = username.length;

  if (usernameLength === 1) {
    initials = username.substring(0, 1).toUpperCase();
  } 
  else if (usernameLength > 1) {
    const firstInitials = username.substring(0, 1).toLowerCase();
    const secondInitials = username.substring(1, 2).toLowerCase();
    initials = firstInitials + secondInitials;
  } else {
    return;
  }

  const canvas=document.createElement('canvas')
  const context=canvas.getContext('2d')
  canvas.width=canvas.height=size

  context.fillStyle="#ffffff"
  context.fillRect(0,0,size,size)

  context.fillStyle=`${color}50`
  context.fillRect(0,0,size,size)

  context.fillStyle='black';
  context.textBaseline='middle'
  context.textAlign='center'
  context.font =`${size/2}px Roboto`
  context.fillText(initials,(size/2),(size/2))

  return canvas.toDataURL()
}
