/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable prefer-const */

/**
 * @namespace model
 * @memberof models
 */

import produce from "immer"
import { invert, getKeyByValue } from "utils"
import {
  ListModelResponse,
  Model,
  UploadDataRequest,
  UploadStatus,
  ListModelFileResponse,
  CopyModelRequest,
  CreateModelRequest,
  UpdateModelRequest,
  ModelConfig,
  ModelInput,
  ModelOutput,
  ModelEnsembling,
  ModelTensorReshape,
  DataType,
  DownloadToken,
  ModelDynamicBatching,
  RequireFilesSatisfied,
  ModelInstanceGroup,
  ModelParameter,
  ModelParam
} from "arti-proto"
import {
  requestPost,
  requestProtoGet,
  requestProtoPost,
  requestProtoDelete,
  requestProtoPut
} from "./api"
import AddPrePostModel from "pages/model/upload/AddPrePostModel"

const getDataTypeNameByValue = invert(DataType)

/**
 * @memberof models.model
 * @const
 * @type {Object}
 * @property {Array} [models] - List of Model
 * @property {Object} [uploaded] - Require files uploaded
 */
const initialState = {
  models: [],
  uploaded: {}
}
const model = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.model
     * @param {State} state - Current State
     * @param {Array} payload - List of Models
     * @return {State} - Changed State
     */
    setModels(state, payload) {
      return produce(state, draft => {
        draft.models = payload
      })
    },
    /**
     * @memberof models.model
     * @param {State} state - Current State
     * @param {Object} payload - Requires file uploaded check
     * @return {State} - Changed State
     */
    checkUploadSatisfied(state, payload) {
      return produce(state, draft => {
        draft.uploaded[payload.id] = payload.check
      })
    }
  },
  effects: dispatch => ({
    /**
     * Reqeust get all model list
     * @memberof models.model
     * @param {String} options Get al model list options
     * @property {request} request
     * @property {query} request.query /model/list
     * @property {type} request.type GET
     * @return {ListModelResponse} ListModelResponse
     */
    async getAll(options, state) {
      const { models } = state.model
      if (state.auth.role !== "SUPER_ADMIN") {
        const response = await dispatch.model.getModels(options)
        return response
      }
      const { data } = await requestProtoGet(`/model/list${options || ""}`)
      const response = ListModelResponse.deserializeBinary(data).toObject()
      await dispatch.model.setModels(models.concat(response.modelsList))
      return response
    },
    /**
     * Request get my models
     * @memberof models.model
     * @param {String} options Get my model options
     * @property {request} request
     * @property {query} request.query /model/my
     * @property {type} request.type GET
     * @return {ListModelResponse} ListModelResponse
     */
    async getModels(options, state) {
      const { models } = state.model
      const { data } = await requestProtoGet(`/model/my${options || ""}`)
      const response = ListModelResponse.deserializeBinary(data).toObject()
      await dispatch.model.setModels(models.concat(response.modelsList))
      return response
    },
    /**
     * Request get my model info
     * @memberof models.model
     * @param {String} id Model id
     * @property {request} request
     * @property {query} request.query /model/my/${id}
     * @property {type} request.type GET
     * @return {Model} Model info
     */
    async getModel(id,auth, model_name) {
      const { data } = await (model_name === undefined
        ? requestProtoGet(`/model/my/${id}`)
        : requestProtoGet(`/model/my/${id}/${model_name}`));

      const response = Model.deserializeBinary(data).toObject()
      response.config.inputList.forEach(v => {
        v.dataType = getDataTypeNameByValue[v.dataType]
      })
      response.config.outputList.forEach(v => {
        v.dataType = getDataTypeNameByValue[v.dataType]
      })
      return response
    },
    async getModelIdFromName(modelName, state){
      console.log(state.model.models)
      const foundModel = state.model.models.find(model => model.name === modelName)
      return foundModel.id

    },
    async getModelConfig(modelId){
      const { data } = await requestProtoGet(`/model/my/${modelId}`)
      const response = Model.deserializeBinary(data).toObject()
      return response.config
    },
    /**
     * Check model validation config
     * @memberof models.model
     * @param {Object} payload - ModelConfig
     * @param {String} payload.name - Model name
     * @param {String} payload.platform - Platform type
     * @param {Array} payload.input - Array of model input config
     * @param {Array} payload.output - Array of model output config
     * @param {Object} payload.ensembleScheduling - Object of ensemble model config
     * @return {ListModelResponse}
     */
    async checkValidateConfig(payload, state) {
      const { name, platform, input, output, ensembleScheduling } = payload
      const { models } = state.model
      const validation = {
        feedback: "",
        valid: true
      }
      function setInvalid(msg) {
        validation.valid = false
        validation.feedback = `${msg}`
      }

      function checkRequired() {
        if (!name) {
          setInvalid("ensemble model name is required")
        }
        if (!platform) {
          setInvalid("platform is required")
        }
        if (!input) {
          setInvalid("ensemble input is required")
        }
        if (!output) {
          setInvalid("ensemble output is required")
        }
        if (!ensembleScheduling) {
          setInvalid("ensembleScheduling is required")
        }
        return validation.valid
      }

      if (!checkRequired()) {
        return validation
      }
      function checkOptions() {
        if (platform !== "ensemble") {
          setInvalid("Platform is not ensemble")
        }
        if (!Array.isArray(input)) {
          setInvalid("input must be array")
        }
        if (!Array.isArray(output)) {
          setInvalid("output must be array")
        }
        // step model Check
        const { step } = ensembleScheduling
        step.some(element => {
          const findedModel = models.find(
            v => v.name === element.modelName
          )
          if (!findedModel) {
            setInvalid(`Cannot found "${element.modelName}" model`)
            return true
          }
          return false
        })
        if (!validation.valid) {
          return validation
        }

        let inputsDataType = {}
        let outputsDataType = {}
        let inputs = [...output.map(v => v.name)]
        let outputs = [...input.map(v => v.name)]
        input.forEach(v => {
          inputsDataType[v.name] = v.dataType
        })
        output.forEach(v => {
          outputsDataType[v.name] = v.dataType
        })
        async function findModelConfig(modelName) {
          const modelId = await dispatch.model.getModelIdFromName(modelName)
          const modelConfig = await dispatch.model.getModelConfig(modelId)
          return modelConfig
        }
        function findModelInput(modelConfig, inputName) {
          let dataType = null
          modelConfig.inputList.some(v => {
            if (v.name === inputName) {
              if (!Number.isNaN(v.dataType)) {
                dataType = getKeyByValue(DataType, v.dataType)
                return true
              }
              dataType = v.dataType
              return true
            }
            dataType = null
            return false
          })
          if (!dataType) {
            return false
          }
          return dataType
        }
        function findModelOutput(modelConfig, outputName) {
          let dataType = null
          modelConfig.outputList.some(v => {
            if (v.name === outputName) {
              if (!Number.isNaN(v.dataType)) {
                dataType = getKeyByValue(DataType, v.dataType)
                return true
              }
              dataType = v.dataType
              return true
            }
            dataType = null
            return false
          })
          if (!dataType) {
            return false
          }
          return dataType
        }
        step.forEach(async v => {
          const inputMaps = Object.values(v.inputMap)
          const outputMaps = Object.values(v.outputMap)
          const inputEntries = Object.entries(v.inputMap)
          const outputEntries = Object.entries(v.outputMap)
          inputs = inputs.concat(inputMaps)
          outputs = outputs.concat(outputMaps)
          const modelConfig = await findModelConfig(v.modelName)
          outputEntries.some(entry => {
            const [key, value] = entry
            
            const dataType = findModelOutput(modelConfig, key)
            if (!dataType) {
              setInvalid(
                `Cannot found output keyname "${key}" in step "${v.modelName}"`
              )
              return true
            }
            inputsDataType[value] = dataType
            return false
          })
          inputEntries.some(entry => {
            const [key, value] = entry
            const dataType = findModelInput(modelConfig, key)
            if (!dataType) {
              setInvalid(
                `Cannot found input keyname "${key}" in step "${v.modelName}"`
              )
              return true
            }
            outputsDataType[value] = dataType
            return false
          })
        })
        if (!validation.valid) {
          return validation
        }
        step.forEach(async v => {
          const inputEntries = Object.entries(v.inputMap)
          const modelConfig = await findModelConfig(v.modelName)
          inputEntries.some(async entry => {
            const [key, value] = entry
            const dataType = findModelInput(modelConfig, key)
            if (dataType !== inputsDataType[value]) {
              setInvalid(
                `DataType of keyname "${key}" in "${v.modelName}" must be "${inputsDataType[value]}"`
              )
              return true
            }
            return false
          })
          const outputEntries = Object.entries(v.outputMap)
          outputEntries.some(entry => {
            const [key, value] = entry
            const dataType = findModelOutput(modelConfig, key)
            if (!outputsDataType[value]) {
              return false
            }
            if (dataType !== outputsDataType[value]) {
              setInvalid(
                `DataType of keyname "${key}" in "${v.modelName}" must be "${outputsDataType[value]}"`
              )
              return true
            }
            return false
          })
        })

        // step duplicateCheck
        const duplicateInput = new Set(inputs)
        const duplicateOutput = new Set(outputs)
        if (duplicateInput.size !== inputs.length) {
          setInvalid("Input name must be unique")
        }
        if (duplicateOutput.size !== outputs.length) {
          setInvalid("Output name must be unique")
        }
        const removeIntersection = inputs.filter(v => outputs.indexOf(v) < 0)
        if (removeIntersection.length !== 0) {
          setInvalid("Inputs and Outputs do not mapped correctly")
        }
        return validation.valid
      }

      if (!checkOptions()) {
        return validation
      }
      return validation
    },
    /**
     * File data convert to json
     * @memberof models.model
     * @param {String} fileData - File String
     * @property {request} request
     * @property {query} request.query /model/convert
     * @property {type} request.type GET
     * @return {String} Converted json confitg
     */
    async convertConfig(fileData) {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token")
        },
        data: fileData
      }
      const response = await requestPost("/model/convert", config)
      try {
        return response.data
      } catch (e) {
        return {}
      }
    },
    async copyModel(payload) {
      const { modelId, newName } = payload

      try {
        const copyModelRequest = new CopyModelRequest()
        copyModelRequest.setModeId(modelId)
        copyModelRequest.setNewName(newName)
        const config = {
          data: copyModelRequest.serializeBinary()
        }
        const { status } = await requestProtoPost("/model/my/copy", config)
        return status
      } catch (error) {
        return error.response.status
      }
    },
    /**
     * Request create model
     * @memberof models.model
     * @param {Object} modelInfo
     * @param {Object} modelInfo.modelConfig Model configs
     * @param {Object} modelInfo.inputConfig Model input configs
     * @param {Object} modelInfo.outputConfig Model output configs
     * @param {Object} modelInfo.ensembling Model ensembling config
     * @property {request} request
     * @property {query} request.query /model/my
     * @property {type} request.type POST
     * @return {Model}
     */
    async createModel(modelInfo, auth, modelId) {
      const {
        config: modelConfig,
        input: inputConfig,
        output: outputConfig,
        dynamicBatching: dynamicbatchingConfig,
        ensembling: ensembleConfig = undefined
      } = modelInfo

      const modelInputList = inputConfig.map(v => {
        const modelInput = new ModelInput()
        modelInput.setName(v.name)
        modelInput.setDimsList(v.dims)
        modelInput.setDataType(DataType[v.dataType])
        if (v.reshape) {
          const modelTensorReshape = new ModelTensorReshape()
          modelTensorReshape.setShapeList(v.reshape.shape)
          modelInput.setReshape(modelTensorReshape)
        }
        return modelInput
      })
      const modelOutputList = outputConfig.map(v => {
        const modelOutput = new ModelOutput()
        modelOutput.setName(v.name)
        modelOutput.setDimsList(v.dims)
        modelOutput.setDataType(DataType[v.dataType])
        modelOutput.setLabelFilename(v.label_filename)
        if (v.reshape) {
          const modelTensorReshape = new ModelTensorReshape()
          modelTensorReshape.setShapeList(v.reshape.shape)
          modelOutput.setReshape(modelTensorReshape)
        }
        return modelOutput
      })

      const modelConfigMessage = new ModelConfig()
      modelConfigMessage.setName(modelConfig.name)
      modelConfigMessage.setPlatform(modelConfig.platform)
      modelConfigMessage.setBackend("sapeon")
      if (modelConfig.platform === "custom") {
        modelConfigMessage.setBackend(modelConfig.backend)
        // modelConfigMessage.setPlatform(modelConfig.platform)
      } else if (modelConfig.platform === "sapeon") {
        modelConfigMessage.setBackend("sapeon")
        // modelConfigMessage.setPlatform(modelConfig.platform)
        const instanceGroup = new ModelInstanceGroup()
        // sapeon default - count: 1, kind: cpu
        instanceGroup.setCount(1)
        instanceGroup.setKind(2)
        modelConfigMessage.setInstanceGroupList([instanceGroup])

        // parameter
        const modelParameter = new ModelParameter()
        const modelParam = new ModelParam()
        modelParam.setStringValue(modelConfig.tensorFormat.value)
        modelParameter.setKey("tensor_format")
        modelParameter.setValue(modelParam)
        modelConfigMessage.setParametersList([modelParameter])
      } else if (modelConfig.platform === "python") {
        modelConfigMessage.setBackend(modelConfig.backend)
        const instanceGroup = new ModelInstanceGroup()
        instanceGroup.setCount(1)
        instanceGroup.setKind(2)
        modelConfigMessage.setInstanceGroupList([instanceGroup])
      }
      
      modelConfigMessage.setMaxBatchSize(modelConfig.maxBatchSize)
      modelConfigMessage.setInputList(modelInputList)
      modelConfigMessage.setOutputList(modelOutputList)
      if (dynamicbatchingConfig) {
        const modelDynamicBatching = new ModelDynamicBatching()
        modelDynamicBatching.setPreferredBatchSizeList(
          dynamicbatchingConfig.batchSize
        )
        modelDynamicBatching.setMaxQueueDelayMicroseconds(
          dynamicbatchingConfig.maxQueueDelayMicroseconds
        )
        modelConfigMessage.setDynamicBatching(modelDynamicBatching)
      }

      // model ensemble create
      if (ensembleConfig) {
        const modelEnsembling = new ModelEnsembling()
        const stepList = ensembleConfig.step.map(v => {
          const step = new ModelEnsembling.Step()
          step.setModelName(v.modelName)
          step.setModelVersion(v.modelVersion)
          let inputMap = step.getInputMapMap()
          let outputMap = step.getOutputMapMap()
          Object.entries(v.inputMap).forEach(entry => {
            const [key, value] = entry
            inputMap.set(key, value)
          })
          Object.entries(v.outputMap).forEach(entry => {
            const [key, value] = entry
            outputMap.set(key, value)
          })
          return step
        })
        modelEnsembling.setStepList(stepList)
        modelConfigMessage.setEnsembleScheduling(modelEnsembling)
      }

      let data, status;
      
      let config;
      
      if (modelId === undefined) {
        const createModelRequest = new CreateModelRequest();
        createModelRequest.setConfig(modelConfigMessage);
        config = {
          data: createModelRequest.serializeBinary(),
        };
      } else {
        const updateModelRequest = new UpdateModelRequest();
        updateModelRequest.setModelId(modelId);
        updateModelRequest.setConfig(modelConfigMessage);
        updateModelRequest.setUpdateModelName(modelConfig.name);
        config = {
          data: updateModelRequest.serializeBinary(),
        };
      }
      
      // Use a single request line after the if block
      ({ data, status } = await (modelId === undefined
        ? requestProtoPost("/model/my", config)
        : requestProtoPut(`/model/my/${modelId}/${modelConfig.name}`, config)));
      
      if (status !== 200) return false;
      
      const response = Model.deserializeBinary(data).toObject();
      return response;
    },
    /**
     * Download model files
     * @memberof models.model
     * @param {Object} info
     * @param {String} info.id - Model id
     * @param {String} info.modelName - Model name
     * @param {String} info.directory - Model directory
     * @param {String} info.fileName - Model fileNname
     * @property {request} request
     * @property {query} request.query - /model/my/${id}/downloads
     * @property {type} request.type - GET
     * @return {Link} ModelDownlaodLink
     */
    async downloadModel(info) {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
      try {
        const { data } = await requestProtoGet(
          `/model/my/${info.id}/download?path=${
            info.directory !== `${info.modelName}`
              ? `${info.directory}/${info.fileName}`
              : `${info.fileName}`
          }`,
          config
        )
        const { token } = DownloadToken.deserializeBinary(data).toObject()
        return token
      } catch (error) {
        return false
      }
    },
    /**
     * @memberof models.model
     * @param {String} modelId - ModelId
     * @return {Boolean}
     */
    /**
     * Request delete model
     * @memberof models.model
     * @param {String} modelId Model Id
     * @property {request} request
     * @property {query} request.query /model/my/{modelId}
     * @property {type} request.type Delete
     * @return {Boolean}
     */
     async deleteModel(modelId) {
      console.log(modelId)
      const { status } = await requestProtoDelete(`/model/my/${modelId}`)
      if (status === 200) {
        await this.getModels()
        return true
      }
      return false
    },
    /**
     * Request delete model
     * @memberof models.model
     * @param {String} modelId Model Id
     * @param {String} prepost Target for delete (pre-process or post-process)
     * @property {request} request
     * @property {query} request.query /model/my/{modelId}
     * @property {type} request.type Delete
     * @return {Boolean}
     */
         async deleteModel(modelId, auth, prepost) {
          const { status } = await requestProtoDelete(`/model/my/${modelId}/${prepost}`)
          if (status === 200) {
            return true
          }
          return false
        },
            /**
     * Request delete model
     * @memberof models.model
     * @param {String} modelId Model Id
     * @param {String} file TargetFile to get bytes
     * @property {request} request
     * @property {query} request.query /model/my/{modelId}/{modelName}/{file}
     * @property {type} request.type GET
     * @return {Byte}
     */
    //Only modelId, model_name are necessary
    //Call API deleteModel(modelID,model_name)
    async getFile(modelId, auth, file) {
      const object = await requestProtoGet(`/model/my/${modelId}/${modelId.split("--")[0]}/${file}`)
      return object
    },
    /**
     * Request get model files
     * @memberof models.model
     * @param {String} modelId
     * @property {request} request
     * @property {query} request.query /model/my/{modelId}/files
     * @property {type} request.type GET
     * @return {Array} Model files list
     */
    async getFiles(modelId) {
      try {
        const { data } = await requestProtoGet(`/model/my/${modelId}/files`)
        const msgObj = ListModelFileResponse.deserializeBinary(data)
        const response = msgObj.toObject()
        return response.filesList
      } catch (e) {
        return []
      }
    },
    /**
     * Request update model
     * @memberof models.model
     * @param {String} modelInfo Update ModelInfo
     * @param {Object} modelInfo.config ModelConfig
     * @param {Object} modelInfo.input ModelInput
     * @param {Object} modelInfo.output ModelOutput
     * @property {request} request
     * @property {query} request.query /model/my
     * @property {type} PUT
     * @return {Model} - Updated model info
     */
    async updateModel(modelInfo) {
      const {
        config: modelConfig,
        input,
        output,
        dynamicBatching: dynamicbatchingConfig
      } = modelInfo
      const modelInputList = input.map(v => {
        const modelInput = new ModelInput()
        modelInput.setName(v.name)
        modelInput.setDimsList(v.dims)
        modelInput.setDataType(DataType[v.dataType])
        if (v.reshape) {
          const modelTensorReshape = new ModelTensorReshape()
          modelTensorReshape.setShapeList(v.reshape.shape)
          modelInput.setReshape(modelTensorReshape)
        }
        return modelInput
      })
      const modelOutputList = output.map(v => {
        const modelOutput = new ModelOutput()
        modelOutput.setName(v.name)
        modelOutput.setDimsList(v.dims)
        modelOutput.setDataType(DataType[v.dataType])
        modelOutput.setLabelFilename(v.label_filename)
        if (v.reshape) {
          const modelTensorReshape = new ModelTensorReshape()
          modelTensorReshape.setShapeList(v.reshape.shape)
          modelOutput.setReshape(modelTensorReshape)
        }
        return modelOutput
      })

      const modelConfigMessage = new ModelConfig()
      modelConfigMessage.setName(modelConfig.name)
      modelConfigMessage.setPlatform(modelConfig.platform)
      if (modelConfig.platform === "custom") {
        modelConfigMessage.setBackend(modelConfig.backend)
      }

      if (modelConfig.platform === "sapeon") {
        modelConfigMessage.setBackend("sapeon")
        const instanceGroup = new ModelInstanceGroup()
        // sapeon default - count: 1, kind: cpu
        instanceGroup.setCount(1)
        instanceGroup.setKind(2)
        modelConfigMessage.setInstanceGroupList([instanceGroup])

        const modelParameter = new ModelParameter()
        const modelParam = new ModelParam()
        modelParam.setStringValue(modelConfig.tensorFormat.value)
        modelParameter.setKey("tensor_format")
        modelParameter.setValue(modelParam)
        modelConfigMessage.setParametersList([modelParameter])
      }

      modelConfigMessage.setMaxBatchSize(modelConfig.maxBatchSize)
      modelConfigMessage.setInputList(modelInputList)
      modelConfigMessage.setOutputList(modelOutputList)      
      if (dynamicbatchingConfig) {
        const modelDynamicBatching = new ModelDynamicBatching()
        modelDynamicBatching.setPreferredBatchSizeList(
          dynamicbatchingConfig.batchSize
        )
        modelDynamicBatching.setMaxQueueDelayMicroseconds(
          dynamicbatchingConfig.maxQueueDelayMicroseconds
        )
        modelConfigMessage.setDynamicBatching(modelDynamicBatching)
      }

      const updateModelRequest = new UpdateModelRequest()
      updateModelRequest.setModelId(modelInfo.modelId)
      updateModelRequest.setConfig(modelConfigMessage)

      const config = {
        data: updateModelRequest.serializeBinary()
      }

      try {
        const { data } = await requestProtoPut("/model/my", config)
        const response = Model.deserializeBinary(data).toObject()
        return response
      } catch {
        return false
      }
    },
    /**
     * Request upload file
     * @memberof models.model
     * @param {String} modelId Model id
     * @param {String} subPath Model sub path
     * @param {Number} total Total file size
     * @param {String} data Chunked file data
     * @param {Object} status
     * @param {Number} status.offset Data length
     * @param {String} status.temp Model id
     * @param {Function} Callback Callback function
     * @property {request} request
     * @property {query} request.query /model/upload
     * @return {UploadStatus} UploadStatus
     * @param {String} prepost Parameter for pre/post model
     */
    async uploadFile({
      modelId,
      subPath,
      total,
      data: fileData,
      status,
      callback = () => {},
      prepost
    }) {
      /**
       * req
       * {
       *   modelId: int
       *   subPath: string
       *   total: int
       *   data: string(bytes)
       *   dataLength: int
       *   status: {
       *     offset: int
       *     temp: string
       *   }
       * }
       *
       * ret
       * {
       *   offset: int
       *   temp: string
       * }
       */
      const uploadStatus = new UploadStatus()
      uploadStatus.setOffset(status.offset)
      uploadStatus.setTemp(status.temp)

      const uploadDataRequest = new UploadDataRequest()
      uploadDataRequest.setSubPath(subPath)
      uploadDataRequest.setTotal(total)
      uploadDataRequest.setData(btoa(fileData))
      uploadDataRequest.setDataLength(fileData.length)
      uploadDataRequest.setStatus(uploadStatus)
      uploadDataRequest.setPrepost(prepost) // setting for PrePost model

      const config = {
        data: uploadDataRequest.serializeBinary(),
      }
      const { data } = await requestProtoPost(
        `/model/${modelId}/upload`,
        config
      )
      const response = UploadStatus.deserializeBinary(data).toObject()
      callback()
      return response
    },
    /**
     * Check upload satisfied
     * @memberof models.model
     * @param {Object} payload
     * @param {String} payload.id Model id
     * @param {Boolean} payload.check Is satisified
     */
    async checkRequires(payload) {
      await dispatch.model.checkUploadSatisfied(payload)
    },
    /**
     * Request model validation
     * @memberof models.model
     * @param {Object} payload
     * @param {String} payload.modelId - modelId
     * @return {Model} - HttpResponseStatus
     */
    async validateModel(payload) {
      const { modelId } = payload
      try {
        const { status } = await requestProtoPut(`/model/validate/${modelId}`)
        return status
      } catch (error) {
        return error.response.status
      }
    },
    async isRequiresSatisfied(payload){
      const {modelId } = payload
      const {data} = await requestProtoGet(`/model/my/${modelId}/satisfied`)
      const response = RequireFilesSatisfied.deserializeBinary(data).toObject();
      return response
    },

    async generateModelGraph(modelId,auth,target){
      const {response} = await requestProtoPut(`/model/my/${modelId}/generateModelGraph/${target}`)
      //const response = RequireFilesSatisfied.deserializeBinary(data).toObject();
      return response
    }
  })
}



export default model
