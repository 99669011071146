/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
import { init } from "@rematch/core"
import audit from "./audit"
import auth from "./auth"
import file from "./file"
import downloads from "./downloads"
import history from "./history"
import model from "./model"
import resource from "./resource"
import users from "./users"
import groups from "./groups"
import inference from "./inference"
import page from "./page"
import platform from "./platform"
import predefined from "./predefined"
import dashboardall from "./dashboardall"

/**
 * @namespace models
 */
export const createStore = () => {
  const store = init({
    models: {
      audit,
      auth,
      file,
      downloads,
      history,
      model,
      resource,
      users,
      groups,
      inference,
      page,
      platform,
      predefined,
      dashboardall
    }
  })
  return store
}
