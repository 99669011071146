/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

/**
 * @memberof components
 * @property {Components} children Children component
 * @property {Boolean} disable - ToolbarInfo disable
 * @property {String} tooltip - Tooltip message
 * @property {Function} onClick - Execute a function when ToolbarIcon clicking
 * @property {String} iconClassName - Set ToolbarIcon ClassName
 * @property {String} btnClassName - Set ToolbarIconButton ClassName
 * @property {Object} style - Set Toolbar icon innerStyles
 */
const ToolbarIcon = ({
  children,
  disabled = false,
  visible,
  tooltip,
  onClick,
  placement = "bottom",
  iconClassName,
  btnClassName = "btn btn-ghost-light icon-only",
  label,
  href,
  backgroundColor = "#39393d",
  style = {
    fontSize: "1.4rem",
    backgroundColor: backgroundColor,
    color: "white"
  }
}) => {
  if (!visible) {
    return null
  }

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 200 }}
      overlay={
        <Tooltip>
          <div>{tooltip}</div>
        </Tooltip>
      }
    >
      {children || (
        <Button
          variant={btnClassName}
          onClick={onClick}
          href={href}
          disabled={disabled}
          style={style}
        >
          <span className={iconClassName} aria-hidden="true" style={style}>
            {label}
          </span>
        </Button>
      )}
    </OverlayTrigger>
  )
}

export default ToolbarIcon
