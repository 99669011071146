import React from "react"
import PropTypes from "prop-types"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CustomUploadAdapter from "./CustomUploadAdapter"

const Editor = ({ data, onChange, style, postId, handleUploadImg }) => {
  const config = {
    extraPlugins: [CustomUploadAdapter],
    postId,
    handleUploadImg
  }
  return (
    <CKEditor
      style={style}
      editor={ClassicEditor}
      config={config}
      data={data}
      onChange={onChange}
    />
  )
}

Editor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func
}

Editor.defaultProps = {
  data: "",
  onChange: () => {}
}

export default Editor
