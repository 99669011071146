/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"
import { Button, Card, Col, ListGroup, Row, Form, Image } from "react-bootstrap"
import FileUploadContainer from "./FileUploadContainer"
import {useDispatch} from "react-redux"
import InfoConfig from "../info/InfoConfig"
import ConfigInputs from "../create/ConfigInputs"
import {handleEnter} from "utils"
import ConfigOutputs from "../create/ConfigOutputs"

const AdvancedOptions = ({ checked, setChecked, type }) => {
  const arrowStyle = checked ? "" : "glyphicons-outline_circle_add"
  if (checked) {
    return (
      <Row className="d-flex justify-content-center">
        <Col className="col-6">
        </Col>
      </Row>
    )
  }
  else {
    return (
      <Row className="d-flex justify-content-center">
        <Col className="col-6">
          <Button
            className="model-advanced-option-btn"
            variant="outline-primary"
            onClick={() => setChecked(!checked)}
          >
            Add {type}
          </Button>
        </Col>
      </Row>
    )
  }
}



function uploadFile(modelId,prepostmodelData) {
  const file = {
    modelId: modelId,
    fileName: prepostmodelData.fileName,
    size: prepostmodelData.size,
    satisfied: prepostmodelData.satisfied,
    created: prepostmodelData.created,
    directory:"/1"
  }
  return file
}

const dataTypes = [
  { value: "TYPE_FP16", label: "TYPE_FP16" },
  { value: "TYPE_FP32", label: "TYPE_FP32" },
  { value: "TYPE_FP64", label: "TYPE_FP64" },
  { value: "TYPE_UINT8", label: "TYPE_UINT8" },
  { value: "TYPE_UINT16", label: "TYPE_UINT16" },
  { value: "TYPE_UINT32", label: "TYPE_UINT32" },
  { value: "TYPE_UINT64", label: "TYPE_UINT64" },
  { value: "TYPE_INT8", label: "TYPE_INT8" },
  { value: "TYPE_INT16", label: "TYPE_INT16" },
  { value: "TYPE_INT32", label: "TYPE_INT32" },
  { value: "TYPE_INT64", label: "TYPE_INT64" },
  { value: "TYPE_BOOL", label: "TYPE_BOOL" },
  { value: "TYPE_STRING", label: "TYPE_STRING" }
]


const AddPrePostModel = props => {
  const {
    data,
    isChecked,
    type,
    file_name,
    modelId,
    //setDynamicBatchingConfig,
    setIsAdvancedChecked,
    modelData,
    prepostmodelData,
    handleResultToast,
    satisfied,
    setUploadFileData,
    configData,
    setConfigData,
    inputConfig,
    setInputConfig,
    outputConfig,
    setOutputConfig,
    handleAddInput,
    handleAddOutput,
    handleRemoveInput,
    handleRemoveOutput,
  } = props
  if (!isChecked) {
    return (
      <AdvancedOptions checked={isChecked} setChecked={setIsAdvancedChecked} type={type}/>
    )
  }
  return (
    <>
      <AdvancedOptions checked={isChecked} setChecked={setIsAdvancedChecked} type={type} />
      <Card>
        <Card.Body>
          <div className="col-12 p-0 upload-config-info">
          <ListGroup.Item className="border-0 pt-3">
          {type === "preprocessing" ? (
              <ConfigInputs
                // dataFormats={dataFormats}
                configData={configData}
                dataTypes={dataTypes}
                // formatCheck={formatCheck}
                onAddInput={handleAddInput}
                onEnter={handleEnter}
                onRemoveInput={handleRemoveInput}
                inputConfig={inputConfig}
                setInputConfig={setInputConfig}
                fontSize={"10px"}
                // onFormatCheck={onFormatCheck}
              />
            ) : (
              <ConfigOutputs
                // dataFormats={dataFormats}
                configData={configData}
                dataTypes={dataTypes}
                // formatCheck={formatCheck}
                onAddOutput={handleAddOutput}
                onEnter={handleEnter}
                onRemoveOutput={handleRemoveOutput}
                outputConfig={outputConfig}
                setOutputConfig={setOutputConfig}
                fontSize={"10px"}
                // onFormatCheck={onFormatCheck}
              />
            )}
        </ListGroup.Item>
          </div>
          <FileUploadContainer
            satisfied={prepostmodelData.satisfied}
            data={uploadFile(modelId, prepostmodelData)}
            handleResultFinish={handleResultToast}
            setUploadFileData={setUploadFileData}
          // showWeight={showWeight}
          // setShowWeight={setShowWeight}
          />
        </Card.Body>
      </Card>

    </>
  )
}

export default AddPrePostModel
