/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"
import { convertFileSizeToString, dateFormat } from "utils"

const FilePreview = ({ fileData, handleIsSpear }) => {
  return (
    <div className="file-preview">
      <span
        className="file-preview-icon glyphicons-file ml-1 mr-2"
        aria-hidden="true"
      />
      <div className="mr-2 text-left">
        <div>
          <span>{String(handleIsSpear(fileData.fileName))}</span>
        </div>
        <div>
          <span className="file-preview-info text-grays-6">
            {convertFileSizeToString(Number(fileData.size))},
            {dateFormat(Number(fileData.created), "YYYY/MM/DD")}
          </span>
        </div>
      </div>
    </div>
  )
}

export default FilePreview
