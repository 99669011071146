/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/**
 * @namespace inference
 * @memberof models
 */

import {
  Application,
  ConfigInfo,
  ConfigMap,
  CreatePredefinedServiceRequest,
  ListPredefinedServiceResponse,
  PredefinedService,
  Service,
  Storage,
  Volume
} from "arti-proto"
import produce from "immer"
import { requestProtoGet, requestProtoPost, requestProtoDelete } from "./api"

const initState = {
  servicesList: [],
  serviceInfo: null,
  images: [{ value: "nginx", label: "Nginx:latest" }]
}

const predefined = {
  state: initState,
  reducers: {
    setPredefinedServicesList(state, payload) {
      return produce(state, draft => {
        draft.servicesList = payload
      })
    },
    setPredefinedService(state, payload) {
      return produce(state, draft => {
        draft.serviceInfo = payload
      })
    }
  },
  effects: dispatch => ({
    async getAll(payload, _) {
      const { data, status } = await requestProtoGet(`predefined/my`)
      if (status === 200) {
        await dispatch.predefined.setPredefinedServicesList([])
        const predefinedList = ListPredefinedServiceResponse.deserializeBinary(
          data
        ).toObject()
        const servicesList = predefinedList.predefinedServicesList
        await dispatch.predefined.setPredefinedServicesList(servicesList)
        return true
      }
      await dispatch.predefined.setPredefinedServicesList([])
      return false
    },
    async getInfo(payload, _) {
      const { predefinedId } = payload
      const { data, status } = await requestProtoGet(
        `predefined/${predefinedId}`
      )
      if (status === 200) {
        const predefinedService = PredefinedService.deserializeBinary(
          data
        ).toObject()
        await dispatch.predefined.setPredefinedService(predefinedService)
        return true
      }
      return false
    },
    async createPredefined(payload, _) {
      const { name, applications, configMaps, inferences, storages } = payload
      const confMaps = configMaps.map((configMap, _) => {
        const conf = new ConfigMap()
        conf.setName(configMap.name)
        // eslint-disable-next-line prefer-const
        let dataMap = conf.getDataMap()
        configMap.datas.map((data, _) => {
          const { key, value } = data
          dataMap.set(key, value)
          return null
        })
        return conf
      })

      const apps = applications.map((application, _) => {
        const app = new Application()
        const volumeList = application.volumes
          ? application.volumes.map((volume, _) => {
              const vol = new Volume()
              vol.setName(volume.name)
              vol.setMountPath(`/${volume.mountPath}`)
              return vol
            })
          : null
        if (volumeList) {
          app.setVolumesList(volumeList)
        }

        const serviceData = application.service
        if (serviceData) {
          const service = new Service()
          service.setContainerPort(Number(serviceData.containerPort))
          if (serviceData.nodePort) {
            service.setNodePort(Number(serviceData.nodePort))
          }
          if (serviceData.path) {
            service.setPath(serviceData.path)
          }
          app.setService(service)
        }
        const configNames = application.configMaps
        if (configNames.length > 0) {
          const configInfos = configNames.map((configName, _) => {
            const configInfo = new ConfigInfo()
            configInfo.setName(configName.name)
            return configInfo
          })
          app.setConfigsList(configInfos)
        }

        app.setName(application.name)
        app.setKind(application.kind.value)
        app.setImage(application.image.value)
        app.setReplicas(application.replicas.value)
        app.setDeviceType(application.deviceType.value)
        return app
      })

      const infers = inferences.map((infer, _) => {
        return infer.id
      })

      const sts = storages.map((st, _) => {
        const storage = new Storage()
        storage.setName(st.name)
        storage.setRequest(st.request)
        storage.setAccessMode(st.accessModeKind.value)
        if (st.storageClass) {
          storage.setStorageClass(st.storageClass)
        }
        return storage
      })
      const createPredefinedReq = new CreatePredefinedServiceRequest()
      createPredefinedReq.setName(name)
      createPredefinedReq.setApplicationsList(apps)
      createPredefinedReq.setConfigmapsList(confMaps)
      createPredefinedReq.setStoragesList(sts)
      createPredefinedReq.setInferenceIdsList(infers)

      const config = {
        data: createPredefinedReq.serializeBinary()
      }
      const { data, status } = await requestProtoPost("predefined/my", config)
      const response = PredefinedService.deserializeBinary(data).toObject()
      if (status === 200) {
        return response
      }
      return false
    },
    async deletePredefinedService(payload) {
      const { predefinedId } = payload
      const { status } = await requestProtoDelete(`predefined/${predefinedId}`)
      if (status === 200) {
        await dispatch.predefined.getAll()
        return true
      }
      return false
    },
    async getImages(payload) {
      // TODO: get Images
    }
  })
}

export default predefined
