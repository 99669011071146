/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react"
import { Button } from "react-bootstrap"
import { faClone } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { addAlert } from "utils"

/**
 * @memberof components
 * @property {Component} children - Children in CopyableButton
 * @property {String} className - Set element className
 * @property {String} textToCopy - Set message for copy
 * @property {IconDefinition} icon - Set CopyableButton icon, Default value '@fortawesome/free-regular-svg-icons'
 * @property {Function} onMouseOver - Execute a function when moving the mouse pointer onto a CopyableButton
 * @property {Function} onMouseOut - Execute a function when moving the mouse pointer out of an image
 * @property {Function} onMouseBlur - Execute a function when a user leaves a CopyableButton
 * @property {Function} onMouseFocus - Execute a function when a CopyableButton gets focus
 */
const CopyableButton = ({
  children,
  className,
  textToCopy,
  icon = faClone,
  onMouseOver,
  onMouseOut,
  onBlur,
  onFocus
}) => {
  const copyTextToClipboard = text => {
    const dummy = document.createElement("textarea")
    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
  }

  const handleClick = e => {
    e.stopPropagation()
    copyTextToClipboard(textToCopy)
    addAlert("Copied to clipboard")
  }
  // FIXME: edit logic after ui confirm
  return (
    <Button
      className={className}
      variant="ghost-black"
      onClick={handleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {children || <FontAwesomeIcon icon={icon} />}
    </Button>
  )
}

export default CopyableButton
