/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import {
  CreatePostRequest,
  Image,
  ListPostResponse,
  Post,
  UpdatePostRequest,
  UploadDataRequest,
  UploadStatus
} from "arti-proto"
import produce from "immer"
import { dateFormat } from "utils"
import {
  requestGet,
  requestProtoGet,
  requestProtoPost,
  requestProtoPut,
  requestProtoDelete
} from "./api"

const initialState = {
  posts: {
    posts: [],
    paging: {
      pageNumber: 0,
      pageSize: 20,
      totalCount: 0
    }
  },
  postData: {
    id: "",
    title: "",
    userId: "",
    type: "",
    content: "",
    completed: "",
    viewCount: "",
    created: null,
    lastModified: null,
    attachmentsList: [],
    imagesList: []
  }
}

const downloads = {
  state: initialState,
  reducers: {
    setPosts(state, payload) {
      return produce(state, draft => {
        draft.posts = payload
      })
    },
    setPost(state, payload) {
      return produce(state, draft => {
        draft.postData = payload
      })
    }
  },
  effects: dispatch => ({
    async getPosts(payload) {
      const {
        downloads: { setPosts }
      } = dispatch
      const { type, pagingOption } = payload
      const { data } = await requestProtoGet(
        `posts/list?type=${type}&${pagingOption}`
      )
      const response = ListPostResponse.deserializeBinary(data).toObject()
      const { pageSize, pageNumber } = response.paging
      const getIndex = _idx => pageSize * pageNumber + _idx + 1
      const posts = response.postsList.map((postItem, idx) => {
        const {
          id: _id,
          userId: _userId,
          type: _type,
          title: _title,
          completed: _completed,
          lastModified: _lastModified,
          attachmentsList: _attachments,
          viewCount: _viewCount,
          created: _created
        } = postItem
        const attachmentsCount = _attachments.length
        return {
          idx: getIndex(idx),
          id: _id,
          userId: _userId,
          type: _type,
          title: _title,
          completed: _completed,
          attachmentsCount,
          viewCount: _viewCount,
          created: dateFormat(_created, "YYYY.MM.DD")
        }
      })
      const postsData = { posts, paging: response.paging }
      setPosts(postsData)
      return response
    },
    async createPost(payload) {
      const { type, title, content, completed } = payload
      const createPostRequest = new CreatePostRequest()
      createPostRequest.setType(type)
      createPostRequest.setTitle(title)
      createPostRequest.setContent(content)
      createPostRequest.setCompleted(completed)
      const config = {
        data: createPostRequest.serializeBinary()
      }
      const { data, status } = await requestProtoPost(`posts/my`, config)
      if (status !== 200) {
        return {
          msg: "Fail create post.",
          status: false
        }
      }
      const response = Post.deserializeBinary(data).toObject()
      const { id: postId } = response
      return {
        msg: "Create post successfully.",
        postId,
        status: true
      }
    },
    async getPost(payload) {
      const { postId } = payload
      await dispatch.downloads.setPost(initialState.postData)
      const { data, status } = await requestProtoGet(`posts/${postId}`)
      if (status !== 200) {
        return {
          msg: "Fail get post info.",
          status: false
        }
      }
      const postData = Post.deserializeBinary(data).toObject()
      await dispatch.downloads.setPost(postData)
      return {
        msg: "Get post info successfully.",
        data: postData,
        status: true
      }
    },
    async deletePost(payload) {
      const { postId } = payload
      const { status } = await requestProtoDelete(`posts/${postId}`)

      if (status !== 200) {
        return {
          msg: `Fail delete post: ${postId}.`,
          status: false
        }
      }
      return {
        msg: `Delete post successfully.`,
        status: true
      }
    },
    async updatePost(payload) {
      const { postId, title, type, content, completed } = payload
      const updatePostRequest = new UpdatePostRequest()
      updatePostRequest.setTitle(title)
      updatePostRequest.setType(type)
      updatePostRequest.setContent(content)
      updatePostRequest.setCompleted(completed)
      const config = {
        data: updatePostRequest.serializeBinary()
      }
      const { status } = await requestProtoPut(`posts/${postId}`, config)
      if (status !== 200) {
        return {
          msg: `Fail delete post: ${postId}.`,
          status: false
        }
      }
      return {
        msg: `Update post successfully.`,
        status: true
      }
    },
    async updateView(payload) {
      const { postId } = payload
      const { status } = await requestProtoPut(`posts/${postId}/view`)
      if (status !== 200) {
        return {
          msg: "Fail update view.",
          status: false
        }
      }
      return {
        msg: "Update view successfully.",
        status: true
      }
    },
    async uploadAttachment(payload) {
      const {
        postId,
        subPath,
        total,
        data: fileData,
        status,
        callback = () => {}
      } = payload

      const uploadStatus = new UploadStatus()
      uploadStatus.setOffset(status.offset)
      uploadStatus.setTemp(status.temp)

      const uploadDataRequest = new UploadDataRequest()
      uploadDataRequest.setSubPath(subPath)
      uploadDataRequest.setTotal(total)
      uploadDataRequest.setData(btoa(fileData))
      uploadDataRequest.setDataLength(fileData.length)
      uploadDataRequest.setStatus(uploadStatus)

      const config = {
        data: uploadDataRequest.serializeBinary()
      }
      const { data } = await requestProtoPost(
        `posts/${postId}/attachments`,
        config
      )
      const response = UploadStatus.deserializeBinary(data).toObject()
      callback()
      return response
    },
    async deleteAttachment(payload) {
      const { postId, subPath } = payload
      const { data, status } = await requestProtoDelete(
        `posts/${postId}/attachments/${subPath}`
      )
      if (status !== 200) {
        return {
          msg: "Fail to delete file.",
          status: false
        }
      }
      return {
        msg: "Delete file successfully.",
        status: true
      }
    },
    async downloadAttachment(payload) {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }
      const { postId, subPath } = payload
      const response = await requestGet(
        `posts/${postId}/attachments/${subPath}`,
        config
      )
      try {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", subPath) // or any other extension
        document.body.appendChild(link)
        return link.click()
      } catch (e) {
        return "error"
      }
    },
    async uploadImage(payload) {
      const {
        postId,
        subPath,
        total,
        data: fileData,
        status,
        callback = () => {}
      } = payload

      const uploadStatus = new UploadStatus()
      uploadStatus.setOffset(status.offset)
      uploadStatus.setTemp(status.temp)

      const uploadDataRequest = new UploadDataRequest()
      uploadDataRequest.setSubPath(subPath)
      uploadDataRequest.setTotal(total)
      uploadDataRequest.setData(btoa(fileData))
      uploadDataRequest.setDataLength(fileData.length)
      uploadDataRequest.setStatus(uploadStatus)

      const config = {
        data: uploadDataRequest.serializeBinary()
      }

      const { data } = await requestProtoPost(`posts/${postId}/images`, config)
      const response = UploadStatus.deserializeBinary(data).toObject()
      callback()
      return response
    }
  })
}

export default downloads
