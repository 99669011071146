// use proxy
import { backEndUrl } from "models/api"
/* eslint-disable no-unused-vars */
class MyUploadAdapter {
  constructor(loader, postId, uploader) {
    // The file loader instance to use during the upload.
    this.loader = loader
    this.postId = postId
    this.uploader = uploader
    this.chunkSize = 2 * 1024 * 1024
  }

  createImgUrl = payload => {
    const { postId, subPath } = payload
    return `${backEndUrl}/posts/${postId}/images/${subPath}`
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(async file => {
      const response = await this.uploader(file)
      const imgUrl = this.createImgUrl(response)
      return { default: imgUrl }
    })
  }
}

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = loader => {
    // eslint-disable-next-line no-underscore-dangle
    const { postId, handleUploadImg } = editor.config._config

    return new MyUploadAdapter(loader, postId, handleUploadImg)
  }
}
