/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */

import React, { useRef } from "react"
import { ListGroup, Accordion, Button, Card, Col, Row } from "react-bootstrap"

const appKind = {
  0: "Deployment",
  1: "StatefulSet"
}
const accessMode = {
  0: "ReadWriteOnce",
  1: "ReadOnlyMany",
  2: "ReadWriteMany"
}
const convertToString = target => {
  if (typeof target === "object") {
    return JSON.stringify(target, null, 2)
  }
  return String(target)
}
const DetailInfo = ({ label, element }) => {
  const LabelInterface = ({ children }) => (
    <>
      <Col className="font-weight-bold col-4">{label}</Col>
      <Col className="col-8">{children}</Col>
    </>
  )
  if (label === "service") {
    return (
      <LabelInterface>
        {Object.keys(element).length > 0 &&
          Object.keys(element).map((k, i) => {
            let clsName = "px-0 border-left-0 border-right-0"
            if (i === 0) {
              clsName += " pt-0 border-top-0"
            } else if (i === Object.keys(element).length - 1) {
              clsName += " border-bottom-0"
            }
            return (
              <ListGroup.Item className={`${clsName}`} key={`${label}-${k}`}>
                <Row key={`${label}-row-${k}`}>
                  <Col className="font-weight-bold col-4">{k}</Col>
                  <Col className="col-8">{convertToString(element[k])}</Col>
                </Row>
              </ListGroup.Item>
            )
          })}
      </LabelInterface>
    )
  }
  if (label === "volumesList") {
    if (element.length === 0) {
      return null
    }
    return (
      <LabelInterface>
        <ListGroup border="none">
          {Object.keys(element).length > 0 &&
            Object.keys(element).map((k, i) => {
              let clsName = ""
              if (k === "0") {
                clsName += "border-top-0 border-bottom-0"
              } else if (k === `${element.length - 1}`) {
                clsName += "border-bottom-0"
              }
              return (
                <ListGroup.Item
                  key={`volumes-list-${label}-${k}`}
                  className={`px-0 pt-0 border-left-0 border-right-0 ${clsName}`}
                >
                  <ListGroup.Item className="px-0 pt-0 border-left-0 border-right-0 border-top-0">
                    <Row key={`${label}-name-${k}`}>
                      <Col className="font-weight-bold col-4">name</Col>
                      <Col className="col-8">{element[k].name}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="px-0 border-left-0 border-right-0 border-bottom-0">
                    <Row key={`${label}-mount-path-${k}`}>
                      <Col className="font-weight-bold col-4">mount Path</Col>
                      <Col className="col-8">{element[k].mountPath}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup.Item>
              )
            })}
        </ListGroup>
      </LabelInterface>
    )
  }
  if (label === "kind") {
    return (
      <LabelInterface>
        <div>{appKind[element]}</div>
      </LabelInterface>
    )
  }
  if (label === "accessMode") {
    return (
      <LabelInterface>
        <div>{accessMode[element]}</div>
      </LabelInterface>
    )
  }
  if (label === "dataMap") {
    return (
      <LabelInterface>
        <ListGroup border="none">
          {element.map((data, i) => {
            const clsName = "px-0 py-0 border-0"
            return (
              <ListGroup.Item className={`${clsName}`} key={`${label}-${i}`}>
                <Row key={`${label}-row-${i}`}>
                  <Col className="col-4">{`${data[0]}:`}</Col>
                  <Col className="col-8">
                    {data.slice(1).map((d, idx) => {
                      return (
                        <Row key={`data-element-row-${idx}`}>
                          {d.split("\n").map((value, index) => {
                            return (
                              <Col key={`data-col-${index}`} className="col-12">
                                {value}
                              </Col>
                            )
                          })}
                        </Row>
                      )
                    })}
                  </Col>
                </Row>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </LabelInterface>
    )
  }
  if (label === "configsList") {
    return (
      <LabelInterface>
        <ListGroup border="none">
          {element.map((conf, i) => {
            const clsName = "px-0 py-0 border-0"
            return (
              <ListGroup.Item className={`${clsName}`} key={`${label}-${i}`}>
                <Row key={`${label}-row-${i}`}>
                  <Col className="col-4 font-weight-bold">name</Col>
                  <Col className="col-8">{conf.name}</Col>
                </Row>
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </LabelInterface>
    )
  }
  if (label === "immutable") {
    return null
  }
  return (
    <LabelInterface>
      <div>{convertToString(element)}</div>
    </LabelInterface>
  )
}

const CollapsesInfo = ({ content, label, type }) => {
  const type1Refs = useRef(new Map()).current

  const toggleClick = (_, index) => {
    const refs = type1Refs

    for (const [mapIndex, value] of refs.entries()) {
      if (index === mapIndex) {
        value.classList.toggle("active")
      } else {
        value.classList.remove("active")
      }
    }
  }
  return (
    <Accordion className="col-12 mb-2">
      <div className="accordion-tit mt-2">{label}</div>
      {content.length > 0 &&
        content.map((element, idx) => {
          return (
            <Card key={`${label}-card-${idx}`}>
              <Card.Header
                id="headingOne"
                ref={inst => type1Refs.set(idx, inst)}
              >
                <Accordion.Toggle
                  as={Button}
                  variant="btn btn-link px-0"
                  eventKey={idx}
                  onClick={() => toggleClick(1, idx)}
                >
                  {element.name}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={idx}>
                <Card.Body className="pl-0">
                  <ListGroup border="none">
                    {Object.keys(element).length > 0 &&
                      Object.keys(element).map((key, i) => {
                        let clsName = ""
                        if (i === 0) {
                          clsName = "border-top-0"
                        } else if (i === Object.keys(element).length - 1) {
                          clsName = "border-bottom-0"
                        }
                        return (
                          <ListGroup.Item
                            key={`${type}-row-${key}`}
                            className={`px-0 border-left-0 border-right-0 ${clsName}`}
                          >
                            <Row key={`${label}-${key}`} className="pl-2">
                              {type === "apps" && (
                                <DetailInfo
                                  label={key}
                                  element={element[key]}
                                />
                              )}
                              {type === "configs" && (
                                <DetailInfo
                                  label={key}
                                  element={element[key]}
                                />
                              )}
                              {type === "inferences" && (
                                <DetailInfo
                                  label={key}
                                  element={element[key]}
                                />
                              )}
                              {type === "storages" && (
                                <DetailInfo
                                  label={key}
                                  element={element[key]}
                                />
                              )}
                            </Row>
                          </ListGroup.Item>
                        )
                      })}
                  </ListGroup>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
    </Accordion>
  )
}

export default CollapsesInfo
