/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"

/**
 * @memberof components
 * @property {Array} steps - Wizard step items
 * @property {Number} current - Current step item index number
 * @property {Function} onLogout - Execute a function when clicking a logout Button
 * @property {Ref} profileEl - Set reference profile elements
 */
const Wizard = props => {
  const { steps, current } = props
  const history = useHistory()
  const handleMoveStep = url => {
    history.push(url)
  }
  return (
    <div className="wizard">
      <ol>
        {steps.map((step, i) => {
          let className = "items "

          if (current === i) {
            className += "current"
          } else if (i < current) {
            className += "disabled"
          }

          return (
            <li className={className} key={i}>
              <span className="stage" onClick={() => handleMoveStep(step.url)}>
                {i + 1}
              </span>
              {step.name}
            </li>
          )
        })}
      </ol>
    </div>
  )
}

Wizard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.array,
  current: PropTypes.number,
  useChecked: PropTypes.bool
}

Wizard.defaultProps = {
  steps: [],
  current: 0,
  useChecked: false
}

export default Wizard
