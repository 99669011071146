import React from "react"
import { Card, Dropdown, ListGroup } from "react-bootstrap"

/**
 * @memberof components
 * @property {Object} auth - authentication info
 * @property {Function} handleProfile - Execute a function when pressing a mouse button over a profile icon
 * @property {Function} onLogout - Execute a function when clicking a logout Button
 * @property {Ref} profileEl - Set reference profile elements
 */
const Profile = props => {
  const { auth, handleDownloads, handleProfile, onLogout, profileEl, isSidebarCollapsed } = props

  const profileIconSize = isSidebarCollapsed ? '40%' : '100%';
  const defaultprofileIconSize = isSidebarCollapsed ? '300%' : '1500%';

  return (
    <Dropdown className="nav-item item-profile" ref={profileEl} style={{textAlign: 'center'}}>
      <Dropdown.Toggle
        className="icon-only"
        id="navbar-profile"
        style={{backgroundColor: 'transparent', border: 'none'}}
      >
        {auth.image !== "" ? 
          <img className="profile-image" style={{height: profileIconSize}} src={auth.image} aria-hidden="black"/> 
          : 
          <span className="glyphicons-user" style={{fontSize: defaultprofileIconSize, borderRadius: "50%", backgroundColor: "black"}} aria-hidden="black" />
        }
        {!isSidebarCollapsed && (
          <div className="username" style={{marginTop: '25%', fontSize: '2em'}}>{auth.username}</div>
        )}
        {!isSidebarCollapsed && (
          <div className="group" style={{marginTop:'20%', color: '#999999'}}>{auth.group}</div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu id="dropdown-profile-menu" className="py-0">
        {!isSidebarCollapsed && (
          <Card style={{width: "auto"}}>
            <Card.Body className="p-0" style={{width: "auto"}}>
              <ListGroup as="ul" variant="flush">
                <ListGroup.Item as="li">
                  <strong>{auth.username}</strong>
                  <small className="d-block text-grays-6">{auth.email}</small>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="pt-1">
                  <ul className="profile-list m-0 p-0">
                    <li className="mt-2">
                      <a
                        onMouseDown={handleProfile}
                        href="#/profile"
                        className="text-grays-9 text-decoration-none"
                      >
                        Profile
                      </a>
                    </li>
                    {auth.role === "SUPER_ADMIN" && (
                      <li className="mt-2">
                        <a
                          onMouseDown={handleDownloads}
                          href="#/information"
                          className="text-grays-9 text-decoration-none"
                        >
                          Notice Board
                        </a>
                      </li>
                    )}
                    <li className="mt-2">
                      <a
                        onMouseDown={e => onLogout(e)}
                        href="#/"
                        className="text-grays-9 text-decoration-none"
                      >
                        <span> Logout </span>
                      </a>
                    </li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Profile
