/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"
/**
 * @memberof components
 * @property {Properties} props
 */
const ActionAlert = () => {
  return (
    <div id="toast-action-alert" className="toast-action-alert">
      <div className="toast-action-content">
        <p
          id="toast-action-alert-message"
          className="toast-action-alert-message"
        />
      </div>
    </div>
  )
}
export default ActionAlert
