/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable no-param-reassign */

/**
 * @namespace platform
 * @memberof models
 */
import produce from "immer"
import { ListPlatformResponse } from "arti-proto"
import { requestProtoGet } from "./api"

/**
 * @memberof models.platform
 * @const
 * @type {Object}
 * @property {Array} platforms
 */
const initialState = {
  platforms: []
}
const platform = {
  state: initialState,
  reducers: {
    /**
     * Set platform list in Redux state
     * @memberof models.platform
     * @param {State} state - Current State
     * @param {Number} payload - Changed State
     * @return {State} - Changed State
     */
    setPlatforms(state, payload) {
      return produce(state, draft => {
        draft.platforms = payload
      })
    }
  },
  effects: dispatch => ({
    /**
     * Get platform list from server
     * @memberof models.platform
     * @function
     * @property {request} request
     * @property {query} request.query "/platform/list"
     * @property {type} request.type GET
     */
    async getPlatforms() {
      const { setPlatforms } = dispatch.platform
      const { data } = await requestProtoGet("/platform/list")
      const response = ListPlatformResponse.deserializeBinary(data).toObject()
      if (response.platformsList === undefined) {
        await setPlatforms([])
        return
      }
      await setPlatforms(response.platformsList)
    }
  })
}

export default platform
