/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from "react"
import { Route } from "react-router-dom"
import SpinnerPage from "components/SpinnerPage"
import { useDispatch } from "react-redux"

/**
 * @memberof components
 * @property {Component} children - PrivateRoute children component
 * @property {Properties} rest - Route properties
 */
const PrivateRoute = ({ children, ...rest }) => {
  const { auth } = useDispatch()
  const { tokenValidation } = auth
  const [fetchComplete, setFetchComplete] = useState(false)

  useEffect(() => {
    async function validationFetch() {
      await tokenValidation()
      setFetchComplete(true)
    }
    validationFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!fetchComplete && !localStorage.getItem("token")) {
    return <SpinnerPage />
  }

  return <Route {...rest}>{children}</Route>
}

export default PrivateRoute
