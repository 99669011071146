/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { Suspense } from "react"
import { HashRouter, Route, Switch } from "react-router-dom"
import PrivateRoute from "components/PrivateRoute"
import NotFound from "pages/redirect/NoAuthorization"
import SpinnerPage from "components/SpinnerPage"
import ActionAlert from "components/ActionAlert"

const loading = () => {
  return <SpinnerPage />
}

/* Routes Pages */
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"))

// FIXME: clean up routing
function App() {
  document.title = "ARTIFERENCE"
  return (
    <>
      <HashRouter>
        <Suspense fallback={loading()}>
          <ActionAlert />
          <Switch>
            <PrivateRoute path="/" name="Home">
              <DefaultLayout />
            </PrivateRoute>
            <Route path="*" name="NotFound" component={NotFound} />
          </Switch>
        </Suspense>
      </HashRouter>
    </>
  )
}
export default App
