/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { Dropzone, ToolbarIcon } from "components"
import { addAlert, S3 } from "utils"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FilePreview from "./FilePreview"

const optionals = [".calib_table", ".cfg", ".weight", "weight.bin"]

const getFilePath = (directory, fileName) => {
  if (directory === "/") {
    return `/${fileName}`
  }
  return `${directory}/${fileName}`
}

const FileUploadContainer = props => {
  const {
    satisfied,
    data,
    handleResultFinish,
  } = props
  const dispatch = useDispatch()
  const [percentage, setPercentage] = useState(-1)
  const [fileUploaderId, setFileUploaderId] = useState(null)
  const [completedCheckerId, setCompletedCheckerId] = useState(null)
  const [fileData, setFileData] = useState({ ...data })
  const chunkSize = 2 * 1024 * 1024 // 2MB

  const onUploadComplete = _fileData => {
    setFileData(_fileData)
    setPercentage(-1)
    //uploadFileData=_fileData
    handleResultFinish({ successful: { length: 1 } })
  }

  const handleFileChange = async files => {
    if (files.length > 1) {
      addAlert("Only one file can be uploaded.")
      return
    }
    let { modelId, directory, fileName } = fileData
    let subPath = getFilePath(directory, fileName)
    const targetFile = files[0]
    // for spear.pb
    if (targetFile.name === "spear.pb" && subPath === "1/sp.pb") {
      subPath = getFilePath("1/binary", "spear.pb")
      // setShowWeight(true)
    }
    // } else if (targetFile.name !== "spear.pb" && subPath === "1/sp.pb") {
    //   setShowWeight(false)
    // }
    const fileSize = targetFile.size
    const sliced = targetFile.slice(0, -1) // exclude last chunk
    targetFile.subPath=subPath
    const uploadConfigTemplate = {
      modelId,
      subPath,
      total: fileSize,
      data: "",
      status: {
        offset: 0,
        temp: ""
      },
      prepost: ""
    }

    if (fileData.fileName=="pre-model.py" ||fileData.fileName=="post-model.py") {
      const prepost = fileData.fileName.split("-")[0]
      uploadConfigTemplate.prepost= prepost + "-process"
    }
    
    // upload empty chunk for getting temp
    console.log(uploadConfigTemplate)
    let { temp } = await dispatch.model.uploadFile(uploadConfigTemplate)
    let completed = 0
    let offset = 0
    let uploadPoolCount = 5
    const fileReader = new FileReader()
    const callbackFunc = result => {
      uploadPoolCount += 1
      completed += result.length
      setPercentage(Math.floor((completed * 100) / fileSize))
      if (completed >= fileSize) {
        onUploadComplete({
          ...fileData,
          satisfied: true,
          size: fileSize,
          created: Date.now()
        })
        if (fileData.fileName=="pre-model.py" || fileData.fileName=="post-model.py") {
          const model_name = modelId.split("-")[0]
          /*S3.deleteObject({
            Bucket: "admin",
            Key: modelId + "/"+ model_name+"/1/" + fileData.fileName,
          }).promise()*/
        }
      }
    }
    fileReader.onload = event => {
      const { result } = event.target
      if (uploadPoolCount < 1) {
        return
      }
      uploadPoolCount -= 1
      dispatch.model.uploadFile({
        ...uploadConfigTemplate,
        data: result,
        status: {
          offset,
          temp
        },
        callback: () => {
          callbackFunc(result)
        }
      })
      offset += result.length
    }

    // upload file
    const fileUploader = setInterval(() => {
      const { readyState } = fileReader
      if (readyState !== 0 && readyState !== 2) return // busy
      const blob = sliced.slice(offset, offset + chunkSize)
      fileReader.readAsBinaryString(blob)
      if (offset >= fileSize - 1) {
        clearInterval(fileUploader)
      }
    }, 125)
    
    // upload last chunk
    const completedChecker = setInterval(() => {
      const { readyState } = fileReader
      if (readyState !== 0 && readyState !== 2) return // busy
      if (completed < fileSize - 1) return // wait until complete to upload sliced
      setPercentage(100)
      const blob = targetFile.slice(fileSize - 1, fileSize)
      fileReader.readAsBinaryString(blob)
      clearInterval(completedChecker)
    }, 500)
    setCompletedCheckerId(completedChecker)
  }

  const handleIsSpear = fileName => {
    if (fileName === "sp.pb") {
      // setShowWeight(true)
      return '"sp.pb" or "spear.pb"'
    }
    return fileName
  }

  const handleCheckOptionalFile = fileName => {
    let optional = false
    optionals.forEach(element => {
      if (fileName.endsWith(element)) {
        optional = true
      }
    })
    return optional ? `(Optional) ${fileName}` : handleIsSpear(fileName)
  }
  useEffect(() => {
    // stop upload when unmounted
    return () => {
      clearInterval(fileUploaderId)
    }
  }, [fileUploaderId])

  useEffect(() => {
    // stop wait when unmounted
    return () => {
      clearInterval(completedCheckerId)
    }
  }, [completedCheckerId])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <span className="font-weight-normal text-size-4">
          <strong>
            {handleCheckOptionalFile(data.fileName)}
            {data.fileName.split(".").pop() === "savedmodel" && (
              <ToolbarIcon
                visible
                tooltip="model.savedmodel file should be a zip file, you need to
                      make a zip file with only savedmodel files not a entire
                      savedmodel directory."
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </ToolbarIcon>
            )}
          </strong>
        </span>
      </div>
      <Dropzone
        onFileChange={handleFileChange}
        progress={percentage}
        // disabled={satisfied}
      >
        {fileData.satisfied && (
          <FilePreview fileData={fileData} handleIsSpear={handleIsSpear} />
        )}
        {fileData.satisfied || <span className="p-2">Drop here or browse</span>}
      </Dropzone>
    </>
  )
}

export default FileUploadContainer

FileUploadContainer.defaultProps = {}

FileUploadContainer.propTypes = {
  data: PropTypes.shape({
    //modelId: PropTypes.string.isRequired,
    directory: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired
  }).isRequired
}
