/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable no-param-reassign */

/**
 * @namespace dashboardall
 * @memberof models
 */
import produce from "immer"
import { requestGet } from "./api"

/**
 * @memberof models.dashboardall
 * @const
 * @type {Object}
 * @property {Array} gpus Gpu List
 */
const initialState = {
  dashboardData : {
  }
}

const dashboardall = {
  state: initialState,
  reducers: {

    setDashboardData(state, payload) {
      return produce(state, draft => {
        draft.dashboardData = payload
      })
    },
  },
  effects: dispatch => ({
    /**
     * Request get dashboard all data
     * @memberof models.resource
     * @function
     * @property {request} request
     * @property {query} request.query /dashboardall/spradm
     * @property {type} request.type GET
     * @return {data} data
     */
    async getSuperAdmin() {
      const { data } = await requestGet("dashboardall/superadmin")
      await dispatch.dashboardall.setDashboardData(data)

      return this.dashboardData;
    },
    /**
     * Request get dashboard all data
     * @memberof models.resource
     * @function
     * @property {request} request
     * @property {query} request.query /dashboardall/grpadm
     * @property {type} request.type GET
     * @return {data} data
     */
    async getGroupAdmin() {
      const { data } = await requestGet("dashboardall/groupadmin")
      await dispatch.dashboardall.setDashboardData(data)

      return this.dashboardData;
    },
    /**
     * Request get dashboard all data
     * @memberof models.resource
     * @function
     * @property {request} request
     * @property {query} request.query /dashboardall/user
     * @property {type} request.type GET
     * @return {data} data
     */
    async getUser() {
      const { data } = await requestGet("dashboardall/user")
      await dispatch.dashboardall.setDashboardData(data)

      return this.dashboardData;
    },
  })
}

export default dashboardall
