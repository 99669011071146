/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */

/**
 * @namespace groups
 * @memberof models
 */
import produce, { produceWithPatches } from "immer"
import { invert } from "utils"
import base64 from "base-64";
import {
  Group,
  GroupApiResponse,
  GetGroupResponse,
  ListGroupResponse,
  ListResourceResponse,
  GroupRegisterRequest,
  UpdateSapeonX220GroupResourceRequest,
  UpdateSapeonX330GroupResourceRequest,
  UpdateGpuGroupResourceRequest,
  Role
} from "arti-proto"
import {
  requestProtoGet,
  requestProtoPost,
  requestProtoPut,
  requestProtoDelete
} from "./api"

const getRoleNameByValue = invert(Role)

// Base64 decoding
const base64DecodeImage = (image) => {
  return image === "" ? image : base64.decode(image)
}

const SAPEON_X220 = "skt.com/aix_v1"
const SAPEON_X330 = "sapeon.com/snx3"
const NVIDIA_GPU = "nvidia.com/gpu"

const initialState = {
  selectedGroup: "",
  groups: [],
  group: {},
  members: [],
  deviceTypes: [],
  total: "",
  sapeonX220: 0,
  sapeonX330: 0,
  gpu: 0,
  sapeonX220GroupResources: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  sapeonX330GroupResources: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  gpuGroupResources: {
    capacity: 0,
    allocatable: 0,
    allocated: 0,
  },
  cpuResources: {}
}

const groups = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.groups
     * @param {State} state - Current State
     * @param {Array} payload - Group List
     * @return {State} - Changed State
     */
    updateGroups(state, payload) {
      return produce(state, draft => {
        draft.groups = payload
      })
    },

    updateMembers(state, payload) {
      return produce(state, draft => {
        draft.members = payload
      })
    },

    updateTotal(state, payload) {
      return produce(state, draft => {
        draft.total = payload
      })
    },

    updateSapeonX220(state, payload) {
      return produce(state, draft => {
        draft.sapeonX220 = payload
      })
    },

    updateSapeonX330(state, payload) {
      return produce(state, draft => {
        draft.sapeonX330 = payload
      })
    },

    updateGpu(state, payload) {
      return produce(state, draft => {
        draft.gpu = payload
      })
    },

    setDeviceType(state, payload) {
      return produce(state, draft => {
        draft.deviceTypes = payload
      })
    },

    setSelectedGroup(state, payload) {
      return produce(state, draft => {
        draft.selectedGroup = payload
      })
    },

    // SAPEON X220 resource
    setSapeonX220GroupResources(state, payload) {
      return produce(state, draft => {
        draft.sapeonX220GroupResources = payload
      })
    },

    // SAPEON X330 resource
    setSapeonX330GroupResources(state, payload) {
      return produce(state, draft => {
        draft.sapeonX330GroupResources = payload
      })
    },

    // NVIDIA GPU resource
    setGpuGroupResources(state, payload) {
      return produce(state, draft => {
        draft.gpuGroupResources = payload
      })
    },

    setCpuResources(state, payload) {
      return produce(state, draft => {
        draft.cpuResources = payload
      })
    }

  },
  effects: dispatch => ({
    /**
     * Request get group list
     * @memberof models.groups
     * @function
     * @param {String} options Get group list option
     * @property {request} request
     * @property {query} request.query /group/list${options}
     * @property {type} request.type GET
     */
    async getGroupList(options) {
      const { updateGroups, updateTotal } = dispatch.groups
      const { data } = await requestProtoGet(`/group/list${options}`)
      const response = ListGroupResponse.deserializeBinary(data).toObject()

      await updateGroups(response.groupinfosList)
      await updateTotal(response.paging.totalCount)
    },

    /**
     * Request get group 
     * @memberof models.groups
     * @function
     * @param {String} options Get group list option
     * @property {request} request
     * @property {query} request.query /group/${groupname}${options}
     * @property {type} request.type GET
     */
    async getGroup(groupname) {
      const { updateSapeonX220, updateSapeonX330, updateGpu } = dispatch.groups
      const { data } = await requestProtoGet(`/group/${groupname}`)
      const response = GetGroupResponse.deserializeBinary(data).toObject()
      await updateSapeonX220(response.sapeonx220)
      await updateSapeonX330(response.sapeonx330)
      await updateGpu(response.gpu)
    },

    /**
     * Request get group resource 
     * @memberof models.groups
     * @function
     * @property {request} request
     * @property {type} request.type GET
     */
    async getGroupResource(groupname) {
      //const { updateSapeon, updateGpu } = dispatch.groups
      const { data } = await requestProtoGet(`/group/${groupname}/resource`)
      const response = ListResourceResponse.deserializeBinary(data).toObject()

      const genLabel = val => {
        if (val === SAPEON_X220) {
          return "X220"
        }
        else if (val === SAPEON_X330) {
          return "X330"
        }
        else if (val === NVIDIA_GPU) {
          return "GPU"
        }
        else {
          return "CPU"
        }
        return val.split("/").length > 1 ? val.split("/")[1] : val
      }

      const deviceTypes = response.resourcesList
        .sort((a, b) => (a.value > b.value) ? 1 : -1)
        .map(deviceType => {
          return {
            value: deviceType.deviceType,
            label: genLabel(deviceType.deviceType),
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable
          }
        })

      deviceTypes.unshift({ value: "cpu", label: "CPU" })

      await dispatch.groups.setCpuResources({
        value: "CPU",
        label: "CPU"
      });

      deviceTypes.forEach(async (deviceType) => {
        if (deviceType.value === "nvidia.com/gpu") {
          await dispatch.groups.setGpuGroupResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === "skt.com/aix_v1") {
          await dispatch.groups.setSapeonX220GroupResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        } else if (deviceType.value === "sapeon.com/snx3") {
          await dispatch.groups.setSapeonX330GroupResources({
            capacity: deviceType.capacity,
            allocatable: deviceType.allocatable,
            allocated: deviceType.capacity - deviceType.allocatable
          });
        }
      })
      dispatch.groups.setDeviceType(deviceTypes)
      //await dispatch.resource.setResources(deviceTypes)
      //return deviceTypes
    },

    /**
     * Request get group member
     * @memberof models.groups
     * @function
     * @param {String} options Get group list option
     * @property {request} request
     * @property {query} request.query /group/${groupname}${options}
     * @property {type} request.type GET
     */
    async getGroupMemberList(groupname) {
      const { updateMembers, updateTotal } = dispatch.groups
      const { data } = await requestProtoGet(`/group/${groupname}`)
      const response = GetGroupResponse.deserializeBinary(data).toObject()
      response.membersList.forEach(v => {
        v.role = getRoleNameByValue[v.role]
        v.image = base64DecodeImage(v.image)
      })
      await updateMembers(response.membersList)
      await updateTotal(response.paging.totalCount)
    },

    /**
     * Request register group
     * @memberof models.groups
     * @function
     * @param {Object} groupFormData
     * @param {String} groupFormData.groupname Groupname
     * @param {String} groupFormData.username Username
     * @param {String} groupFormData.password Password
     * @param {String} groupFormData.email Email
     * @param {String} groupFormData.role Role
     * @param {int32} groupFormData.sapeonX220 SapeonX220
     * @param {int32} groupFormData.sapeonX330 SapeonX330
     * @param {int32} groupFormData.gpu Gpu
     * @property {request} request
     * @property {query} request.query /group/register
     * @property {type} request.type POST
     */
    async registerGroup(groupFormData) {
      const groupRegisterRequest = new GroupRegisterRequest()
      groupRegisterRequest.setGroup(groupFormData.groupname)
      groupRegisterRequest.setUsername(groupFormData.username)
      groupRegisterRequest.setPassword(groupFormData.password)
      groupRegisterRequest.setEmail(groupFormData.email)
      groupRegisterRequest.setRole(Role[groupFormData.role])
      groupRegisterRequest.setSapeonx220(groupFormData.sapeonX220)
      groupRegisterRequest.setSapeonx330(groupFormData.sapeonX330)
      groupRegisterRequest.setGpu(groupFormData.gpu)
      const config = {
        data: groupRegisterRequest.serializeBinary()
      }

      try {
        const { data } = await requestProtoPost("/group/register", config)
        return GroupApiResponse.deserializeBinary(data).toObject()
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },

    /**
     * Request update sapeon x220 resource for group
     * @memberof models.groups
     * @function
     * @property {request} request
     * @property {query} request.query /group/${groupname}/resource/sapeon/x220
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateSapeonX220Resource(values) {
      const updateSapeonGroupResourceRequest = new UpdateSapeonX220GroupResourceRequest()
      updateSapeonGroupResourceRequest.setSapeonx220(values.sapeonX220)
      const config = {
        data: updateSapeonGroupResourceRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPut(`/group/${values.groupname}/resource/sapeon/x220`, config)
        return GroupApiResponse.deserializeBinary(data).toObject()
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },

    /**
     * Request update sapeon x330 resource for group
     * @memberof models.groups
     * @function
     * @property {request} request
     * @property {query} request.query /group/${groupname}/resource/sapeon/x330
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateSapeonX330Resource(values) {
      const updateSapeonGroupResourceRequest = new UpdateSapeonX330GroupResourceRequest()
      updateSapeonGroupResourceRequest.setSapeonx330(values.sapeonX330)
      const config = {
        data: updateSapeonGroupResourceRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPut(`/group/${values.groupname}/resource/sapeon/x330`, config)
        return GroupApiResponse.deserializeBinary(data).toObject()
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },

    /**
     * Request update sapeon resource for gpu
     * @memberof models.groups
     * @function
     * @property {request} request
     * @property {query} request.query /group/${groupname}/resource/gpu
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateGpuResource(values) {
      const updateGpuGroupResourceRequest = new UpdateGpuGroupResourceRequest()
      updateGpuGroupResourceRequest.setGpu(values.gpu)
      const config = {
        data: updateGpuGroupResourceRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPut(`/group/${values.groupname}/resource/gpu`, config)
        return GroupApiResponse.deserializeBinary(data).toObject()
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },

    /**
     * Request delete group
     * @memberof models.groups
     * @function
     * @param {String} groupname Group name
     * @property {request} request
     * @property {query} request.query /group/${groupname}
     * @property {type} request.type Delete
     * @return {Boolean}
     */
    async deleteGroup(groupname) {
      try {
        await requestProtoDelete(`/group/${groupname}`)
        return true
      } catch {
        return false
      }
    }
  })
}

export default groups