/* eslint-disable no-param-reassign */
import produce from "immer"

const initialState = {
  new: false,
  history: []
}

const history = {
  state: initialState,
  reducers: {
    addHistory(_, payload) {
      return produce(_, draft => {
        draft.new = true
        if (draft.history.length < 10) {
          draft.history.splice(0, 0, payload)
          return
        }
        draft.history.splice(0, 0, payload)
        draft.history.splice(10, 1)
      })
    },
    initHistory(_, payload) {
      return produce(_, draft => {
        draft.history = payload
      })
    },
    popHistory(_, payload) {
      return produce(_, draft => {
        draft.history.splice(payload, 1)
      })
    },
    resetHistory(_, payload) {
      return produce(_, draft => {
        draft.history = payload
      })
    },
    checkNew(_, payload) {
      return produce(_, draft => {
        draft.new = payload
      })
    }
  },
  effects: dispatch => ({
    async checkHistory(payload, state) {
      const { auth } = state
      localStorage.setItem(`historyCheck-${auth.username}`, false)
      await dispatch.history.checkNew(false)
    },
    async setInitHistory(payload, state) {
      const { auth } = state
      await dispatch.history.checkNew(
        localStorage.getItem(`historyCheck-${auth.username}`) === "true"
      )
      await dispatch.history.initHistory(payload)
    },
    async setHistory(payload, state) {
      const { auth } = state
      const config = {
        log: payload,
        date: new Date().toLocaleString()
      }
      if (!localStorage.getItem(auth.username)) {
        localStorage.setItem(auth.username, JSON.stringify([config]))
      } else {
        const historyData = [
          ...JSON.parse(localStorage.getItem(auth.username)),
          config
        ]
        if (historyData.length > 10) {
          historyData.splice(0, historyData.length - 10)
        }
        localStorage.setItem(auth.username, JSON.stringify(historyData))
      }
      localStorage.setItem(`historyCheck-${auth.username}`, true)
      dispatch.history.addHistory(config)
    },
    async removeHistory(payload) {
      dispatch.history.popHistory(payload)
    },
    async removeAllHistory() {
      dispatch.history.resetHistory([])
    }
  })
}

export default history
