/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */

/**
 * @namespace users
 * @memberof models
 */
import produce from "immer"
import { invert } from "utils"
import base64 from "base-64";
import {
  ListUserResponse,
  SignupRequest,
  Role,
  UserApiResponse,
  UpdateUserPasswordRequest,
  UpdateUserRoleRequest,
  UpdateUserEmailRequest,
  UploadProfileImageRequest,
  ValidatePasswordRequest,
  User
} from "arti-proto"
import {
  requestProtoGet,
  requestProtoPost,
  requestProtoPut,
  requestProtoDelete
} from "./api"

const getRoleNameByValue = invert(Role)

// Base64 decoding
const base64DecodeImage = (image) => {
  return image === "" ? image: base64.decode(image)
}

const initialState = {
  users: [],
  total: "",
  userFilterMenus:[],
  userMenus: [],
}
const users = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.users
     * @param {State} state - Current State
     * @param {Array} payload - User List
     * @return {State} - Changed State
     */
    updateUsers(state, payload) {
      return produce(state, draft => {
        draft.users = payload
      })
    },
    /**
     * @memberof models.users
     * @param {State} state - Current State
     * @param {Array} payload - User TotalCount
     * @return {State} - Changed State
     */
    updateTotal(state, payload) {
      return produce(state, draft => {
        draft.total = payload
      })
    },
    setUserMenus(state, payload) {
      return produce(state, draft => {
        draft.userMenus = payload
      })
    },
    setUserFilterMenus(state, payload) {
      return produce(state, draft => {
        draft.userFilterMenus = payload
      })
    },
  },
  effects: dispatch => ({
    /**
     * Request get user list
     * @memberof models.users
     * @function
     * @param {String} options Get user list option
     * @property {request} request
     * @property {query} request.query /user/list${options}
     * @property {type} request.type GET
     */
    async getUsers(options) {
      const { updateUsers, updateTotal } = dispatch.users
      const { data } = await requestProtoGet(`/user/list${options}`)
      const response = ListUserResponse.deserializeBinary(data).toObject()
      response.usersList.forEach(v => {
        v.role = getRoleNameByValue[v.role]
        v.image = base64DecodeImage(v.image)
      })

      const convertedUsers = response.usersList.map(userEl => ({
          id: userEl.id,
          value: userEl.username,
          label: userEl.username,
          role: userEl.role,
          image: userEl.image,
        }))
      await this.setUserFilterMenus([...convertedUsers])
      await this.setUserMenus([
        {id: "top5", value: "top5", label: "Top 5 users", "role": "ADMIN"},
        ...convertedUsers
      ])
      await updateUsers(response.usersList)
      await updateTotal(response.paging.totalCount)
      return convertedUsers
    },
    /**
     * Request register user
     * @memberof models.users
     * @function
     * @param {Object} userFormData
     * @param {String} userFormData.username Username
     * @param {String} userFormData.password Password
     * @param {String} userFormData.email Email
     * @param {String} userFormData.role Role
     * @param {String} userFormData.group Group
     * @property {request} request
     * @property {query} request.query /signup
     * @property {type} request.type POST
     */
    async registerUser(userFormData) {
      const signupRequest = new SignupRequest()
      signupRequest.setUsername(userFormData.username)
      signupRequest.setPassword(userFormData.password)
      signupRequest.setEmail(userFormData.email)
      signupRequest.setRole(Role[userFormData.role.value])
      signupRequest.setGroup(userFormData.group.value)
      const config = {
        data: signupRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPost("/signup", config)
        const response = UserApiResponse.deserializeBinary(data).toObject()
        return response
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },
    /**
     * Request update my password
     * @memberof models.users
     * @function
     * @param {Object} values
     * @param {String} values.currentPassword Current password
     * @param {String} values.newPassword New Password
     * @property {request} request
     * @property {query} request.query /user/password
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    /*
    async updatePassword(values) {
      const updatePasswordRequest = new UpdatePasswordRequest()
      updatePasswordRequest.setCurrentPassword(values.currentPassword)
      updatePasswordRequest.setUpdatedPassword(values.newPassword)
      const config = {
        data: updatePasswordRequest.serializeBinary()
      }
      const token = localStorage.getItem("token")
      try {
        await requestProtoPut("/user/password", config)
        return true
      } catch (error) {
        localStorage.setItem("token", token)
        return false
      }
    },
    */
    /**
     * Request update user password
     * @memberof models.users
     * @function
     * @param {Object} values
     * @param {String} values.user_id user id
     * @param {String} values.password New password
     * @property {request} request
     * @property {query} request.query /user/auth/password
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateUserPassword(values) {
      const { user_id, password } = values
      const updateUserPasswordRequest = new UpdateUserPasswordRequest()
      updateUserPasswordRequest.setId(user_id)
      updateUserPasswordRequest.setPassword(password)
      const config = {
        data: updateUserPasswordRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPost(`/user/auth/password`, config)
        const response = UserApiResponse.deserializeBinary(data).toObject()
        return response
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },
    /**
     * Request get user info
     * @memberof models.users
     * @function
     * @param {String} username Username
     * @property {request} request
     * @property {query} request.query /user/${username}
     * @property {type} request.type GET
     * @return {User} User Infomation
     */
    async getUserInfo(username) {
      const { data } = await requestProtoGet(`/user/${username}`)
      const response = User.deserializeBinary(data).toObject()
      response.role = getRoleNameByValue[response.role]
      response.image = base64DecodeImage(response.image)
      return response
    },
    /**
     * Request update user email
     * @memberof models.users
     * @function
     * @param {Object} values
     * @param {String} values.user_id user id
     * @param {String} values.email email
     * @property {request} request
     * @property {query} request.query /user/auth/email
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateUserEmail(values) {
      const { user_id, email } = values
      const updateUserEmailRequest = new UpdateUserEmailRequest()
      updateUserEmailRequest.setId(user_id)
      updateUserEmailRequest.setEmail(email)
      const config = {
        data: updateUserEmailRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPost(`/user/auth/email`, config)
        const response = UserApiResponse.deserializeBinary(data).toObject()
        return response
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },
    /**
     * Request update user role
     * @memberof models.users
     * @function
     * @param {Object} values
     * @param {String} values.user_id user id
     * @param {String} values.role email
     * @property {request} request
     * @property {query} request.query /user/auth/email
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateUserRole(values) {
      const { user_id, role } = values
      const updateUserRoleRequest = new UpdateUserRoleRequest()
      updateUserRoleRequest.setId(user_id)
      updateUserRoleRequest.setRole(Role[role])
      const config = {
        data: updateUserRoleRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPost(`/user/role`, config)
        const response = UserApiResponse.deserializeBinary(data).toObject()
        return response
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },
    /**
     * Request proifle upload image
     * @memberof models.users
     * @function
     * @param {Object} values
     * @param {String} values.username Username
     * @param {String} values.imageFileName image file name
     * @param {String} values.imageFileType image file type
     * @param {string} values.image image binary data
     * @param {Integer} values.length image file length
     * @property {request} request
     * @property {query} request.query /user/${username}/profile/image
     * @property {type} request.type POST
     * @return {Boolean}
     */
    async uploadProfileImage(values) {
      const uploadProfileImageRequest = new UploadProfileImageRequest()
      uploadProfileImageRequest.setUsername(values.username)
      uploadProfileImageRequest.setImage(base64.encode(values.image))
      uploadProfileImageRequest.setFormat(values.imageFileType)
      uploadProfileImageRequest.setLength(values.length)
      const config = {
        data: uploadProfileImageRequest.serializeBinary()
      }
      try {
        const response = await requestProtoPost(`/user/profile/image`, config)
        return response
      } catch {
        return false
      }
    },
    /**
     * Request validate password
     * @memberof models.users
     * @function
     * @param {Object} values
     * @param {String} values.username Username
     * @param {String} values.password Password
     * @property {request} request
     * @property {query} request.query /user/auth/validate
     * @property {type} request.type POST
     * @return {Boolean}
     */
     async validatePassword(values) {
      const validatePasswordRequest = new ValidatePasswordRequest()
      validatePasswordRequest.setUsername(values.username)
      validatePasswordRequest.setPassword(values.password)
      const config = {
        data: validatePasswordRequest.serializeBinary()
      }
      try {
        const { data } = await requestProtoPost(`/user/auth/validate`, config)
        const response = UserApiResponse.deserializeBinary(data).toObject()
        return response
      } catch {
        return {
          code: 500,
          msg: 'internal server error'
        }
      }
    },
    /**
     * Request delete user
     * @memberof models.users
     * @function
     * @param {String} username Username
     * @property {request} request
     * @property {query} request.query /user/${username}
     * @property {type} request.type Delete
     * @return {Boolean}
     */
    async deleteUser(username) {
      try {
        await requestProtoDelete(`/user/${username}`)
        return true
      } catch {
        return false
      }
    }
  })
}

export default users
