/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"

/**
 * Renders a SpinnerPage component
 * @memberof components
 */
const SpinnerPage = () => {
  return (
    <div
      className="d-flex w-100 h-100 align-items-center position-static"
      style={{ minHeight: "700px" }}
    >
      <div className="col-12 text-center">
        <div className="d-flex col-12 justify-content-center mb-4">
          <div className="col-6">
            <h1>Page Loading... </h1>
          </div>
        </div>
        <div className="d-flex col-12 justify-content-center">
          <div className="col-6">
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpinnerPage
