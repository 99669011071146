/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @module api
 */
import axios from "axios"

// const { protocol, hostname } = window.location
// Use proxy to avoid CORS
// const backEndHost = `${protocol}//${hostname}`
// const backEndPort = window.artiference.env.BACKEND_PORT || "30090"
/**
 * @export api
 * @const
 * @type {String}
 * @example {protocol}://{hostName}:{portNumber}
 */
// Use proxy to avoid CORS
// export const backEndUrl = `${backEndHost}:${backEndPort}/api`
export const backEndGateway = `${window.artiference.env.BACKEND_URL}`
export const backEndUrl = `${window.artiference.env.BACKEND_URL}/api`

const defaultRetryConfig = {
  retry: 3,
  delay: 30
}

export const setupInterceptors = (store, config = defaultRetryConfig) => {
  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      error.config.retry = error.config.retry || 0
      error.config.retry += 1
      if (error.config.retry < config.retry) {
        return new Promise(resolve =>
          setTimeout(() => resolve(axios.request(error.config)), config.delay)
        )
      }
      if (!!error.response && [401, 403].includes(error.response.status)) {
        // localStorage.removeItem("token")
        // store.dispatch.auth.loginSuccess(false)
      }
      return Promise.reject(error)
    }
  )
}

const createHeaders = headers => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: localStorage.getItem("token"),
    ...headers
  }
}

const createProtoHeader = headers => {
  return {
    "Content-Type": "application/x-protobuf;charset=UTF-8",
    Accept: "application/x-protobuf;charset=UTF-8, application/json",
    Authorization: localStorage.getItem("token"),
    ...headers
  }
}

/**
 * Get type HttpRequest
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestGet = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "get",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    headers: createHeaders(config.headers)
  })
  return response
}

/**
 * POST type HttpRequest
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestPost = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "post",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    headers: createHeaders(config.headers)
  })
  return response
}

/**
 * PUT type HttpRequest
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestPut = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "put",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    headers: createHeaders(config.headers)
  })
  return response
}

/**
 * Delete type HttpRequest
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestDelete = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "delete",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    headers: createHeaders(config.headers)
  })
  return response
}

/**
 * "GET" type HttpRequest using protobuff messages
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestProtoGet = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "get",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    responseType: "arraybuffer",
    headers: createProtoHeader(config.header)
  })
  return response
}

/**
 * "POST" type HttpRequest using protobuff messages
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestProtoPost = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "post",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    responseType: "arraybuffer",
    headers: createProtoHeader(config.header)
  })
  return response
}

/**
 * "PUT" type HttpRequest using protobuff messages
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestProtoPut = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "put",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    responseType: "arraybuffer",
    headers: createProtoHeader(config.header)
  })
  return response
}

/**
 * "DELETE" type HttpRequest using protobuff messages
 * @export api
 * @function
 * @param {String} url - Url Path
 * @param {Object} config - Axios Config
 * @return {HttpResponse}
 */
export const requestProtoDelete = async (url, config = {}) => {
  const response = await axios.request({
    ...config,
    method: "delete",
    // use proxy
    baseURL: backEndUrl,
    url,
    // url: combineURLs(BACKEND_URL_PREFIX, url),
    responseType: "arraybuffer",
    headers: createProtoHeader(config.header)
  })
  return response
}
// Request Config (https://github.com/axios/axios#request-config)
//
// {
//   // `url` is the server URL that will be used for the request (required)
//   url: '/user',

//   // `method` is the request method to be used when making the request
//   method: 'get', // default

//   // `baseURL` will be prepended to `url` unless `url` is absolute.
//   // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
//   // to methods of that instance.
//   baseURL: 'https://some-domain.com/api/',

//   // `transformRequest` allows changes to the request data before it is sent to the server
//   // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
//   // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
//   // FormData or Stream
//   // You may modify the headers object.
//   transformRequest: [function (data, headers) {
//     // Do whatever you want to transform the data

//     return data;
//   }],

//   // `transformResponse` allows changes to the response data to be made before
//   // it is passed to then/catch
//   transformResponse: [function (data) {
//     // Do whatever you want to transform the data

//     return data;
//   }],

//   // `headers` are custom headers to be sent
//   headers: {'X-Requested-With': 'XMLHttpRequest'},

//   // `params` are the URL parameters to be sent with the request
//   // Must be a plain object or a URLSearchParams object
//   params: {
//     ID: 12345
//   },

//   // `paramsSerializer` is an optional function in charge of serializing `params`
//   // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
//   paramsSerializer: function (params) {
//     return Qs.stringify(params, {arrayFormat: 'brackets'})
//   },

//   // `data` is the data to be sent as the request body
//   // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
//   // When no `transformRequest` is set, must be of one of the following types:
//   // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
//   // - Browser only: FormData, File, Blob
//   // - Node only: Stream, Buffer
//   data: {
//     firstName: 'Fred'
//   },

//   // syntax alternative to send data into the body
//   // method post
//   // only the value is sent, not the key
//   data: 'Country=Brasil&City=Belo Horizonte',

//   // `timeout` specifies the number of milliseconds before the request times out.
//   // If the request takes longer than `timeout`, the request will be aborted.
//   timeout: 1000, // default is `0` (no timeout)

//   // `withCredentials` indicates whether or not cross-site Access-Control requests
//   // should be made using credentials
//   withCredentials: false, // default

//   // `adapter` allows custom handling of requests which makes testing easier.
//   // Return a promise and supply a valid response (see lib/adapters/README.md).
//   adapter: function (config) {
//     /* ... */
//   },

//   // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
//   // This will set an `Authorization` header, overwriting any existing
//   // `Authorization` custom headers you have set using `headers`.
//   // Please note that only HTTP Basic auth is configurable through this parameter.
//   // For Bearer tokens and such, use `Authorization` custom headers instead.
//   auth: {
//     username: 'janedoe',
//     password: 's00pers3cret'
//   },

//   // `responseType` indicates the type of data that the server will respond with
//   // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
//   //   browser only: 'blob'
//   responseType: 'json', // default

//   // `responseEncoding` indicates encoding to use for decoding responses
//   // Note: Ignored for `responseType` of 'stream' or client-side requests
//   responseEncoding: 'utf8', // default

//   // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
//   xsrfCookieName: 'XSRF-TOKEN', // default

//   // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
//   xsrfHeaderName: 'X-XSRF-TOKEN', // default

//   // `onUploadProgress` allows handling of progress events for uploads
//   onUploadProgress: function (progressEvent) {
//     // Do whatever you want with the native progress event
//   },

//   // `onDownloadProgress` allows handling of progress events for downloads
//   onDownloadProgress: function (progressEvent) {
//     // Do whatever you want with the native progress event
//   },

//   // `maxContentLength` defines the max size of the http response content in bytes allowed
//   maxContentLength: 2000,

//   // `validateStatus` defines whether to resolve or reject the promise for a given
//   // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
//   // or `undefined`), the promise will be resolved; otherwise, the promise will be
//   // rejected.
//   validateStatus: function (status) {
//     return status >= 200 && status < 300; // default
//   },

//   // `maxRedirects` defines the maximum number of redirects to follow in node.js.
//   // If set to 0, no redirects will be followed.
//   maxRedirects: 5, // default

//   // `socketPath` defines a UNIX Socket to be used in node.js.
//   // e.g. '/var/run/docker.sock' to send requests to the docker daemon.
//   // Only either `socketPath` or `proxy` can be specified.
//   // If both are specified, `socketPath` is used.
//   socketPath: null, // default

//   // `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
//   // and https requests, respectively, in node.js. This allows options to be added like
//   // `keepAlive` that are not enabled by default.
//   httpAgent: new http.Agent({ keepAlive: true }),
//   httpsAgent: new https.Agent({ keepAlive: true }),

//   // 'proxy' defines the hostname and port of the proxy server.
//   // You can also define your proxy using the conventional `http_proxy` and
//   // `https_proxy` environment variables. If you are using environment variables
//   // for your proxy configuration, you can also define a `no_proxy` environment
//   // variable as a comma-separated list of domains that should not be proxied.
//   // Use `false` to disable proxies, ignoring environment variables.
//   // `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and
//   // supplies credentials.
//   // This will set an `Proxy-Authorization` header, overwriting any existing
//   // `Proxy-Authorization` custom headers you have set using `headers`.
//   proxy: {
//     host: '127.0.0.1',
//     port: 9000,
//     auth: {
//       username: 'mikeymike',
//       password: 'rapunz3l'
//     }
//   },

//   // `cancelToken` specifies a cancel token that can be used to cancel the request
//   // (see Cancellation section below for details)
//   cancelToken: new CancelToken(function (cancel) {
//   })
// }

// Response Schema (https://github.com/axios/axios#response-schema)
//
// {
//   // `data` is the response that was provided by the server
//   data: {},

//   // `status` is the HTTP status code from the server response
//   status: 200,

//   // `statusText` is the HTTP status message from the server response
//   statusText: 'OK',

//   // `headers` the headers that the server responded with
//   // All header names are lower cased
//   headers: {},

//   // `config` is the config that was provided to `axios` for the request
//   config: {},

//   // `request` is the request that generated this response
//   // It is the last ClientRequest instance in node.js (in redirects)
//   // and an XMLHttpRequest instance in the browser
//   request: {}
// }
