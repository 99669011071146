/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */
import Keycloak from "keycloak-js"
import produce from "immer"
import base64 from "base-64";
import { invert } from "utils"
import { User, UpdateUserRequest, Role, Token } from "arti-proto"
import { requestPost, requestProtoGet, requestProtoPut } from "./api"

const keycloakUrl =  window.artiference.env.KEYCLOAK_URL
                    || "https://localhost:30090/auth"
const redirectUrl = window.artiference.env.KEYCLOAK_REDIRECT_URL
                    || "https://localhost:30090"
const getRoleNameByValue = invert(Role)

const keycloak = new Keycloak({
  url: keycloakUrl, // keycloak server 주소
  realm: "artiference", // keycloak admin에서 생성한 realm 이름
  clientId: "artiference", // keycloak admin에서 해당 app과 연결한 clientId
  "enable-cors": true
})
keycloak.onAuthLogout = function() {
  keycloak.logout()
}
keycloak.onTokenExpired = function() {
  window.location.replace(redirectUrl)
  keycloak.logout()
}

const initialState = {
  username: "",
  user_id: "",
  image: "",
  email: "",
  role: "",
  token: "",
  group: "",
  login: false
}
/**
 * @namespace auth
 * @memberof models
 */
const auth = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.auth
     * @param {Payload} payload - Current State
     * @param {Boolean} payload.login - True
     * @return {State} - Changed State
     */
    loginSuccess(_, payload) {
      return produce(_, draft => {
        draft.login = payload.login
        draft.token = payload.token
      })
    },
    /**
     * @memberof models.auth
     * @param {State} state - Current State
     * @return {State} - Initialized State
     */
    logout(state) {
      return produce(state, () => {
        state = initialState
      })
    },
    /**
     * @memberof models.auth
     * @param {State} state Current State
     * @param {Object} payload Payload Data
     * @param {String} payload.id User id
     * @param {String} payload.username Username
     * @param {String} payload.email Email
     * @param {String} payload.role "SUPER_ADMIN" or "GROUP_ADMIN" or "USER"
     * @param {String} payload.group Group name
     * @return {State}  Changed State
     */
    setUserInfo(state, payload) {
      return produce(state, draft => {
        draft.user_id = payload.id
        draft.username = payload.username
        draft.image = base64.decode(payload.image)
        draft.email = payload.email
        draft.role = payload.role
        draft.token = payload.token
        draft.group = payload.group
      })
    }
  },
  effects: dispatch => ({
    /**
     * Reqeust SignIn
     * @memberof models.auth
     * @param {Object} formData - SignIn FormData
     * @param {String} formData.username - userName
     * @param {String} formData.password - userPassword
     * @property {request} request
     * @property {query} request.query /signin
     * @property {type} request.type POST
     * @return {Number} HttpStatusCode
     */
    async signIn(formData) {
      const body = JSON.stringify({
        username: formData.username,
        password: formData.password
      })
      const config = {
        headers: {},
        data: body
      }

      // signin & save token in localStorage
      // localStorage.removeItem("token")
      const status = await requestPost("/signin", config)
        .then(async value => {
          if (value.headers.authorization) {
            localStorage.setItem("token", value.headers.authorization)
            await dispatch.auth.loginSuccess({login:true, token: value.headers.authorization})
          }
          await this.getMyInfo()
          return value.status
        })
        .catch(err => {
          return err.response.status
        })
      return status
    },
    /**
     * SignOut user
     * @memberof models.auth
     */
    async signOut() {
      localStorage.removeItem("token")
      keycloak.logout()
      const { logout } = dispatch.auth
      dispatch.history.removeAllHistory()
      logout(initialState)
    },
    /**
     * Request Get My Info
     * @memberof models.auth
     * @property {request} request
     * @property {query} request.query /user/my
     * @property {type} request.type GET
     * @return {Number} HttpStatusCode
     */
    async getMyInfo() {
      const { setUserInfo } = dispatch.auth
      const { data, status } = await requestProtoGet("/user/my")
      const response = User.deserializeBinary(data).toObject()
      response.role = getRoleNameByValue[response.role]
      setUserInfo(response)
      return status
    },
    /**
     * Reqeust Update My Info
     * @memberof models.auth
     * @param {String} value - Update Email Address
     * @property {request} request
     * @property {query} request.query /user/my
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateMyInfo(value) {
      const updateUserRequest = new UpdateUserRequest()
      updateUserRequest.setEmail(value)
      const config = {
        data: updateUserRequest.serializeBinary()
      }
      try {
        const { status } = await requestProtoPut("/user/my", config)
        if (status !== 200) return false
        return true
      } catch {
        return false
      }
    },
    /**
     * Reqeust Update User Info
     * @memberof models.auth
     * @param {Object} values
     * @param {String} values.username Username
     * @param {String} values.email Update Email Address
     * @param {String} values.role ADMIN or USER
     * @property {request} request
     * @property {query} request.query /user/my
     * @property {type} request.type PUT
     * @return {Boolean}
     */
    async updateUserInfo(values) {
      const { username, email, role } = values
      const updateUserRequest = new UpdateUserRequest()
      updateUserRequest.setEmail(email)
      updateUserRequest.setRole(Role[role])
      const config = {
        data: updateUserRequest.serializeBinary()
      }
      try {
        const { status } = await requestProtoPut(`/user/${username}`, config)
        if (status !== 200) return false
        return true
      } catch {
        return false
      }
    },
    /**
     * Check Login Token Validation
     * @memberof models.auth
     * @property {request} request
     * @property {query} request.query /user/my
     * @property {type} request.type GET
     */
    async tokenValidation() {
      keycloak
        .init({ onLoad: "login-required", checkLoginIframe: false, responseMode:"query" })
        .success(async (auth) =>{
            if(!auth){
              keycloak.login({
                redirectUri: redirectUrl,
                locale: "en" // login page locale 설정. 'en' or 'ko' 설정.
              }) // keycloak login page로 redirect
              return  
            }
            localStorage.setItem("token", `Bearer ${keycloak.token}`)
          try {
            const { data, status } = await requestProtoGet("/user/my")
            const response = User.deserializeBinary(data).toObject()
            response.role = getRoleNameByValue[response.role]
            if (status === 200) {
              await dispatch.auth.loginSuccess({login: true, token: keycloak.token})
              await dispatch.auth.setUserInfo(response)
            }
          } catch {
            // localStorage.removeItem("token")
            await dispatch.auth.loginSuccess({login: false, token: ""})
          }
        })
    },
    /**
     * Reqeust Get User Token
     * @memberof models.auth
     * @param {String} payload Username
     * @property {request} request
     * @property {query} request.query /user/${payload}/token
     * @property {type} request.type GET
     * @return {String} Response Token
     */
    async getUserToken(payload) {
      const { data } = await requestProtoGet(`/auth/${payload}/token`)
      const response = Token.deserializeBinary(data).toObject()
      try {
        return response.token
      } catch (err) {
        return null
      }
    },
    /**
     * Reqeust Refresh User Token
     * @memberof models.auth
     * @param {String} payload Token
     * @property {request} request
     * @property {query} request.query /api/token
     * @property {type} request.type POST
     * @return {String} Response Refreshed Token
     */
    async refreshToken(payload) {
      const config = {
        headers: {
          Authorization: `Bearer ${payload}`
        }
      }
      const res = await requestPost(`/auth/token`, config)
      const response = await this.updateToken(res.data.token)
      return response
    },
    /**
     * Reqeust Refresh User Token
     * @memberof models.auth
     * @param {String} payload Token
     * @property {request} request
     * @property {query} request.query /api/token
     * @property {type} request.type PUT
     * @return {String} Response Updated Token
     */
    async updateToken(payload) {
      const config = {
        headers: {
          Authorization: `Bearer ${payload}`
        }
      }
      const { data } = await requestProtoPut("/auth/token", config)
      const response = Token.deserializeBinary(data).toObject()
      return response.token
    }
  })
}

export default auth
