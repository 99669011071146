/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useRef, useState } from "react"
import { Button, Carousel, Col, Form, ListGroup, Row } from "react-bootstrap"
import { ToolbarIcon, Select } from "components"
import produce from "immer"

const inputStyle = {
  borderRadius: "0.25rem",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#e5e5ea",
}
const ConfigInputs = props => {
  const {
    // dataFormats,
    configData,
    dataTypes,
    editCheck,
    // formatCheck,
    onAddInput,
    onEnter,
    onRemoveInput,
    inputConfig,
    setInputConfig,
    fontSize,
    // onFormatCheck
  } = props
  const isFirst = useRef(true)
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)
  const maxIndex = inputConfig.input_data.length - 1
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }
  const handlePrev = () => {
    if (index > 0) {
      setIndex(prev => prev - 1)
      return
    }
    if (index === 0) {
      setIndex(maxIndex)
    }
  }
  const handleNext = () => {
    if (index < maxIndex) {
      setIndex(prev => prev + 1)
      return
    }
    if (index === maxIndex) {
      setIndex(0)
    }
  }

  const handleRemove = () => {
    if (index > 0) {
      setIndex(prev => prev - 1)
      return
    }
    setIndex(0)
  }
  const getNextFieldId = event => {
    if (event.keyCode !== 13) {
      return false
    }
    if (inputConfig.input_data.length > index + 1) {
      const nextIndex = index + 1
      handleNext()
      return `input_data[${nextIndex}].input_name`
    }
    const outputIndex = localStorage.getItem("modelOutputIndex")
    return `output_data[${outputIndex}].output_name`
  }
  const onChangeInputName = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.input_name = e.target.value
    })
    setInputConfig(prev => {
      return {
        input_data: [
          ...prev.input_data.slice(0, idx),
          changed,
          ...prev.input_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeDataType = (value, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.input_data_type = value
    })
    setInputConfig(prev => {
      return {
        input_data: [
          ...prev.input_data.slice(0, idx),
          changed,
          ...prev.input_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeDims = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.input_dims = e.target.value
    })
    setInputConfig(prev => {
      return {
        input_data: [
          ...prev.input_data.slice(0, idx),
          changed,
          ...prev.input_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeReshape = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.input_reshape = e.target.value
    })
    setInputConfig(prev => {
      return {
        input_data: [
          ...prev.input_data.slice(0, idx),
          changed,
          ...prev.input_data.slice(idx + 1)
        ]
      }
    })
  }
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false
      localStorage.setItem("modelInputIndex", 0)
      return
    }
    if (inputConfig.input_data.length - 1 < index) {
      setIndex(0)
      localStorage.setItem("modelInputIndex", 0)
      document.getElementById(`input_data[0].input_name`).focus()
      return
    }
    localStorage.setItem("modelInputIndex", index)
    document.getElementById(`input_data[${index}].input_name`).focus()
  }, [index, inputConfig.input_data.length])

  return (
    <Row className="d-flex align-items-start">
      <Col className="font-weight-bold col-15 col-md-4 mt-5"> Input </Col>
      <Col className="col-15 col-md-8">
        <div className="d-flex mt-5 mt-md-0">
          <Carousel
            className="input-list-caroucel col-12 p-3"
            style={{ ...inputStyle, fontSize: fontSize }}
            activeIndex={index}
            direction={direction}
            onSelect={handleSelect}
            slide={false}
            prevIcon={
              <Button
                className="p-0"
                variant="btn btn-ghost-light icon-only"
                onClick={() => handlePrev()}
              >
                <span className="btn-carousel-icon glyphicons-uxs_previous" />
              </Button>
            }
            nextIcon={
              <Button
                className="p-0"
                variant="btn btn-ghost-light icon-only"
                onClick={() => handleNext()}
              >
                <span className="btn-carousel-icon glyphicons-uxs_next" />
              </Button>
            }
            interval={null}
          >
            {inputConfig.input_data.map((ele, idx) => {
              return (
                <Carousel.Item key={idx}>
                  <ListGroup>
                    <ListGroup.Item className="border-0 pt-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`input_data[${idx}].input_name`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Name </div>
                          </Col>
                          <Col>
                            <Form.Control
                              name={`input_data[${idx}].input_name`}
                              type="text"
                              placeholder="input name"
                              onChange={e => onChangeInputName(e, ele, idx)}
                              onKeyDown={e => onEnter(e)}
                              value={ele.input_name}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`input_data[${idx}].input_data_type`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Data type </div>
                          </Col>
                          <Col>
                            <Select
                              isDisabled={editCheck}
                              options={dataTypes}
                              onChange={value =>
                                onChangeDataType(value, ele, idx)
                              }
                              onKeyDown={e => onEnter(e)}
                              value={
                                ele.input_data_type
                                  ? dataTypes.find((type) => type.value === ele.input_data_type)
                                  : null
                              }
                              //value={ele.input_data_type}
                              maxMenuHeight={120}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    {/* <ListGroup.Item className="border-0 pr-0">
                      <Row className="align-items-center">
                        <Col className="col-12 col-lg-4">
                          <div className="d-flex">
                            <div> Format</div>
                            <div>
                              <Form.Check
                                name={`input_data[${idx}].input_format_check`}
                                className="format-check ml-3 mt-0"
                                type="checkbox"
                                checked={!!formatCheck[idx]}
                                onChange={() =>
                                  onFormatCheck(idx, !formatCheck[idx])
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <Form.Control
                            disabled={!formatCheck[idx]}
                            as="select"
                            name={`input_data[${idx}].input_format`}
                            placeholder="128"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={ele.input_format}
                          >
                            {dataFormats.map(element => {
                              return (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.value}
                                </option>
                              )
                            })}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item> */}
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`input_data[${idx}].input_dims`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Dims </div>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              name={`input_data[${idx}].input_dims`}
                              placeholder="[299,299,3]"
                              onChange={e => onChangeDims(e, ele, idx)}
                              onKeyDown={e => onEnter(e)}
                              value={ele.input_dims}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`input_data[${idx}].input_reshape`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Reshape </div>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              name={`input_data[${idx}].input_reshape`}
                              placeholder="[299,299,3]"
                              onChange={e => onChangeReshape(e, ele, idx)}
                              onKeyDown={e => onEnter(e, getNextFieldId(e))}
                              value={ele.input_reshape}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                  </ListGroup>
                </Carousel.Item>
              )
            })}
          </Carousel>

          <div
            className={`config-input-toolbar ${
              editCheck ? `d-none` : `ml-2 h-100`
            }`}
            style={{
              zIndex: "100"
            }}
          >
            <ToolbarIcon
              visible
              tooltip="Add Input Item"
              onClick={() => {
                onAddInput(configData)
                setIndex(prev => prev + 1)
              }}
              iconClassName="glyphicons-outline_add"
            />
            <ToolbarIcon
              visible
              tooltip="Remove Input Item"
              onClick={() => {
                onRemoveInput()
                handleRemove()
              }}
              iconClassName="glyphicons-outline_remove"
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default ConfigInputs
