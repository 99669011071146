/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable react/require-default-props */
import React from "react"
import { Button } from "react-bootstrap"

import PropTypes from "prop-types"

/**
 * @memberof components
 * @property  {String} title - Set error status code
 * @property  {String} msg - Set error message
 * @property  {String} onBackClick - Execute a function when a BackButton click
 */
const ErrorPage = props => {
  const { title, msg, onBackClick } = props

  return (
    <div className="pattern-body pattern-centered">
      <div className="container">
        <div className="row justify-content-center">
          <div className="error">
            <div className="error-title">
              <strong>{title}</strong>
            </div>
            <div className="error-msg">
              <p>{msg}</p>
            </div>
            <Button
              variant="primary"
              className="btn-lg"
              onClick={() => onBackClick()}
            >
              Go Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

ErrorPage.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  onBackClick: PropTypes.func
}

ErrorPage.defaultProps = {
  title: "",
  msg: ""
}

export default ErrorPage
