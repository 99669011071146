import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"
import produce from "immer"
import classNames from "classnames"

const defaultTheme = theme => {
  return produce(theme, draft => {
    draft.colors.primary = "#5267f6" // cornflowerblue
    draft.colors.primary25 = "#cbd1fc"
    draft.colors.outline = "#e5d5ea"
    draft.colors.neutral15 = "#d8d8d8"
  })
}

function getBackgroundColor() {
  if (this.isSelected) return this.theme.colors.neutral15
  if (this.isFocused) return this.theme.colors.neutral5
  return "transparent"
}

const defaultStyles = {
  indicatorSeparator: () => {
    return {
      display: "none"
    }
  },
  control: (provided, state) => {
    const { menuIsOpen, isFocused } = state
    const { colors } = state.theme
    return produce(provided, draft => {
      draft.boxShadow = "none"
      draft.borderColor = isFocused ? colors.primary25 : colors.outline
      draft["&:hover"] = menuIsOpen ? colors.primary25 : colors.outline
    })
  },
  dropdownIndicator: (provided, state) => {
    const { menuIsOpen } = state.selectProps
    const { colors } = state.theme
    return produce(provided, draft => {
      draft.color = menuIsOpen ? colors.neutral30 : colors.outline
      draft["&:hover"] = {
        color: menuIsOpen ? colors.neutral30 : colors.outline
      }
    })
  },
  option: (provided, state) => {
    const { colors } = state.theme
    const { isSelected } = state
    return produce(provided, draft => {
      draft.color = colors.neutral90
      draft.backgroundColor = getBackgroundColor.call(state)
      draft["&:active"] = {
        color: colors.neutral90,
        backgroundColor: isSelected ? colors.neutral20 : colors.neutral10
      }
    })
  },
  menu: provided => {
    return produce(provided, draft => {
      draft.marginTop = 2
    })
  }
}

const errorStyles = {
  ...defaultStyles,
  valueContainer: provided => {
    return produce(provided, draft => {
      draft.backgroundImage = `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='%23ff5632' d='M10.0000095,1.5c-4.6943965,0-8.5,3.805542-8.5,8.5c0,4.694397,3.805603,8.5,8.5,8.5s8.5-3.805603,8.5-8.5 C18.5000095,5.305542,14.6944065,1.5,10.0000095,1.5z M9.2587986,5.8630371h1.4824219v5.7739258H9.2587986V5.8630371z M10.0000095,14.3000412c-0.4832153,0-0.875-0.3917847-0.875-0.875c0-0.4832764,0.3917847-0.875,0.875-0.875 c0.4832764,0,0.875,0.3917236,0.875,0.875C10.8750095,13.9082565,10.4832859,14.3000412,10.0000095,14.3000412z'/%3e%3c/svg%3e")`
      draft.backgroundRepeat = "no-repeat"
      draft.backgroundPosition = "center right"
      draft.backgroundSize = "19px 19px"
    })
  },
  control: (provided, state) => {
    const { colors } = state.theme
    return produce(provided, draft => {
      draft.boxShadow = "none"
      draft.borderColor = colors.danger
      draft["&:hover"] = {
        borderColor: colors.danger
      }
    })
  }
}
/**
 * @memberof components
 * @property {Boolean} isValid - Validation Check for applying styles
 * @property {String} feedback - Feedback message
 * @property {Array} options - Select options
 */
const Select = props => {
  const [styles, setStyles] = useState(defaultStyles)
  const { invisible, isValid, feedback } = props

  useEffect(() => {
    setStyles(isValid ? defaultStyles : errorStyles)
  }, [isValid])

  if (invisible) {
    return null
  }
  return (
    <>
      <ReactSelect
        styles={styles}
        theme={defaultTheme}
        isSearchable={false}
        blurInputOnSelect
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
      {/* classname "invalid-feekback", "d-block" are from bootstarp(https://getbootstrap.com/) */}
      <div className={classNames("invalid-feedback", { "d-block": !isValid })}>
        {feedback}
      </div>
    </>
  )
}

export default Select

Select.propTypes = {
  isValid: PropTypes.bool,
  feedback: PropTypes.string,
  options: PropTypes.instanceOf(Array)
}

Select.defaultProps = {
  isValid: true,
  feedback: "",
  options: []
}
