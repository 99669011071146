/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useRef, useState } from "react"
import { Button, Carousel, Col, Form, ListGroup, Row } from "react-bootstrap"
import { Select, ToolbarIcon } from "components"
import produce from "immer"

const outputStyle = {
  borderRadius: "0.25rem",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#e5e5ea"
}
const ConfigOutputs = props => {
  const {
    configData,
    dataTypes,
    editCheck,
    outputConfig,
    onAddOutput,
    onEnter,
    onRemoveOutput,
    setOutputConfig,
    fontSize
  } = props
  const isFirst = useRef(true)
  const [index, setIndex] = useState(0)
  const [direction, setDirection] = useState(null)
  const maxIndex = outputConfig.output_data.length - 1

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setDirection(e.direction)
  }
  const handlePrev = () => {
    if (index > 0) {
      setIndex(prev => prev - 1)
      return
    }
    if (index === 0) {
      setIndex(maxIndex)
    }
  }
  const handleNext = () => {
    if (index < maxIndex) {
      setIndex(prev => prev + 1)
      return
    }
    if (index === maxIndex) {
      setIndex(0)
    }
  }

  const handleRemove = () => {
    if (index > 0) {
      setIndex(prev => prev - 1)
      return
    }
    setIndex(0)
  }
  const getNextFieldId = event => {
    if (event.keyCode !== 13) {
      return false
    }
    if (outputConfig.output_data.length > index + 1) {
      const nextIndex = index + 1
      handleNext()
      return `output_data[${nextIndex}].output_name`
    }
    return "maxBatchSize"
  }
  const onChangeOutputName = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.output_name = e.target.value
    })
    setOutputConfig(prev => {
      return {
        output_data: [
          ...prev.output_data.slice(0, idx),
          changed,
          ...prev.output_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeDataType = (value, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.output_data_type = value
    })
    setOutputConfig(prev => {
      return {
        output_data: [
          ...prev.output_data.slice(0, idx),
          changed,
          ...prev.output_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeDims = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.output_dims = e.target.value
    })
    setOutputConfig(prev => {
      return {
        output_data: [
          ...prev.output_data.slice(0, idx),
          changed,
          ...prev.output_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeLabelName = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.label_file_name = e.target.value
    })
    setOutputConfig(prev => {
      return {
        output_data: [
          ...prev.output_data.slice(0, idx),
          changed,
          ...prev.output_data.slice(idx + 1)
        ]
      }
    })
  }
  const onChangeReshape = (e, ele, idx) => {
    const changed = produce(ele, draft => {
      draft.output_reshape = e.target.value
    })
    setOutputConfig(prev => {
      return {
        output_data: [
          ...prev.output_data.slice(0, idx),
          changed,
          ...prev.output_data.slice(idx + 1)
        ]
      }
    })
  }
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false
      localStorage.setItem("modelOutputIndex", 0)
      return
    }
    if (outputConfig.output_data.length - 1 < index) {
      setIndex(0)
      localStorage.setItem("modelOutputIndex", 0)
      document.getElementById(`output_data[0].output_name`).focus()
      return
    }
    localStorage.setItem("modelOutputIndex", index)
    document.getElementById(`output_data[${index}].output_name`).focus()
  }, [index, outputConfig.output_data.length])

  return (
    <Row className="d-flex align-items-start pt-5">
      <Col className="font-weight-bold col-12 col-md-4 mt-5"> Output </Col>
      <Col className="col-12 col-md-8 ">
        <div className="d-flex mt-5 mt-md-0">
          <Carousel
            className="output-list-caroucel col-12 p-3"
            style={{ ...outputStyle, fontSize: fontSize }}
            activeIndex={index}
            direction={direction}
            onSelect={handleSelect}
            slide={false}
            prevIcon={
              <Button
                className="p-0"
                variant="btn btn-ghost-light icon-only"
                onClick={() => handlePrev()}
              >
                <span className="btn-carousel-icon glyphicons-uxs_previous" />
              </Button>
            }
            nextIcon={
              <Button
                className="p-0"
                variant="btn btn-ghost-light icon-only"
                onClick={() => handleNext()}
              >
                <span className="btn-carousel-icon glyphicons-uxs_next" />
              </Button>
            }
            interval={null}
          >
            {outputConfig.output_data.map((ele, idx) => {
              return (
                <Carousel.Item key={idx}>
                  <ListGroup>
                    <ListGroup.Item className="border-0 pr-0 pt-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`output_data[${idx}].output_name`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">Name</Col>
                          <Col>
                            <Form.Control
                              type="text"
                              name={`output_data[${idx}].output_name`}
                              placeholder="output name"
                              onChange={e => onChangeOutputName(e, ele, idx)}
                              onKeyDown={e => onEnter(e)}
                              value={ele.output_name}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`output_data[${idx}].output_data_type`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Data type </div>
                          </Col>
                          <Col>
                            <Select
                              isDisabled={editCheck}
                              options={dataTypes}
                              onChange={value =>
                                onChangeDataType(value, ele, idx)
                              }
                              onKeyDown={e => onEnter(e)}
                              value={
                                ele.output_data_type
                                  ? dataTypes.find((type) => type.value === ele.output_data_type)
                                  : null
                              }
                              //value={ele.output_data_type}
                              maxMenuHeight={170}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`output_data[${idx}].output_dims`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Dims </div>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              name={`output_data[${idx}].output_dims`}
                              placeholder="[1001]"
                              onChange={e => onChangeDims(e, ele, idx)}
                              onKeyDown={e => onEnter(e)}
                              value={ele.output_dims}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`output_data[${idx}].label_file_name`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Label File Name </div>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              name={`output_data[${idx}].label_file_name`}
                              placeholder="labels.txt"
                              onChange={e => onChangeLabelName(e, ele, idx)}
                              onKeyDown={e => onEnter(e)}
                              value={ele.label_file_name}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 pr-0">
                      <Form.Group
                        className="mb-0"
                        controlId={`output_data[${idx}].output_reshape`}
                      >
                        <Row className="align-items-center">
                          <Col className="col-12 col-lg-4">
                            <div> Reshape </div>
                          </Col>
                          <Col>
                            <Form.Control
                              type="text"
                              name={`output_data[${idx}].output_reshape`}
                              placeholder="[299,299,3]"
                              onChange={e => onChangeReshape(e, ele, idx)}
                              onKeyDown={e => onEnter(e, getNextFieldId(e))}
                              value={ele.output_reshape}
                              disabled={editCheck}
                              style={{fontSize: fontSize }}
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>
                    </ListGroup.Item>
                  </ListGroup>
                </Carousel.Item>
              )
            })}
          </Carousel>
          <div
            className={`config-output-toolbar ${
              editCheck ? `d-none` : `ml-2 h-100`
            }`}
            style={{
              zIndex: "100"
            }}
          >
            <ToolbarIcon
              visible
              tooltip="Add Output Item"
              onClick={() => {
                onAddOutput(configData)
                setIndex(prev => prev + 1)
              }}
              iconClassName="glyphicons-outline_add"
            />
            <ToolbarIcon
              visible
              tooltip="Remove Output Item"
              onClick={() => {
                onRemoveOutput()
                handleRemove()
              }}
              iconClassName="glyphicons-outline_remove"
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default ConfigOutputs
