/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"
import { ListGroup, Col, Row } from "react-bootstrap"
import InfoInputs from "./InfoInputs"
import InfoOutputs from "./InfoOutputs"

const InfoConfig = ({ data, fontSize}) => {
  return (
    <>
      <ListGroup.Item>
        <Row>
          <Col className="font-weight-bold col-4">Input</Col>
          <Col>
            <InfoInputs data={data.config} fontSize={fontSize}/>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item className="pb-2">
        <Row>
          <Col className="font-weight-bold col-4">Output</Col>
          <Col>
            <InfoOutputs data={data.config} fontSize={fontSize}/>
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  )
}

export default InfoConfig
