/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Modal } from "react-bootstrap"

const Footer = ({ isDialog, onClose, onConfirm }) => {
  if (isDialog) {
    return (
      <Modal.Footer>
        <Button
          id="popup-close-btn"
          className="col-6 btn-lg btn-outline-light"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          id="popup-confirm-btn"
          className="col-6 btn-lg btn-secondary"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    )
  }
  return (
    <Modal.Footer>
      <Button
        id="popup-close-btn"
        className="col-12 btn-lg btn-outline-light"
        onClick={onClose}
      >
        Close
      </Button>
    </Modal.Footer>
  )
}

/**
 * @memberof components
 * @property {Boolean} isDialog - If is dialog, set focus on close button
 * @property {Function} onClose - Execute a function when click close Button
 * @property {Function} onConfirm - Execute a function when click confirm Button
 * @property {Boolean} show - If is true, show PopUp
 * @property {String} title - Set PopUp title
 * @property {Component} contents - Set PopUp contents
 */
const PopUp = props => {
  const { isDialog, onClose, onConfirm, show, title, contents } = props

  useEffect(() => {
    if (!show) {
      return
    }
    if (isDialog) {
      document.getElementById("popup-confirm-btn").focus()
      return
    }
    document.getElementById("popup-close-btn").focus()
  }, [isDialog, show])

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <h1>{title}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-grays-65">
        <p>{contents}</p>
      </Modal.Body>
      <Footer isDialog={isDialog} onClose={onClose} onConfirm={onConfirm} />
    </Modal>
  )
}

export default PopUp

PopUp.propTypes = {
  isDialog: PropTypes.bool,
  show: PropTypes.bool
}

PopUp.defaultProps = {
  isDialog: false,
  show: false
}
