/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react"
import { Badge } from "react-bootstrap"

/**
 * @memberof components
 * @property {String} className - Badge ElementsClassName
 * @property {Component} content - Badge content
 */
const StatusBadge = ({ content, className }) => {
  const getVariant = status => {
    const indexing = {
      Running: "success",
      Pending: "warning",
      Creating: "info",
      Failed: "danger"
    }
    if (!Object.prototype.hasOwnProperty.call(indexing, status)) return ""
    return indexing[status]
  }
  return (
    <Badge className={className} variant={getVariant(content)}>
      {content}
    </Badge>
  )
}

export default StatusBadge
