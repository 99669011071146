/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-use-before-define */
import React, { useEffect } from "react"
import { useInfiniteScroll } from "custom-hook"

/**
 * @memberof components
 * @property {Component} children - Children in InfiniteScroll
 * @property {String} className - Set element className
 * @property {Function} fetchMore - Execute a function fetchMorePage
 * @property {Boolean} hasMore - Whether there are more items to be loaded.
 * @property {Component} loader - A React component to render while more items are loading.
 * @property {Component} endMessage - If not more page exists, set endMessage
 */
const InfiniteScroll = props => {
  const { children, fetchMore, hasMore, loader, endMessage } = props
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreItems)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      return
    }
    setIsFetching(true)
  }
  function fetchMoreItems() {
    setTimeout(() => {
      fetchMore()
      setIsFetching(false)
    })
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener("scroll", handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isFetching) {
      return
    }
    fetchMore()
  }, [fetchMore, isFetching])

  return (
    <>
      {children}
      {isFetching && hasMore && loader}
      {!hasMore && endMessage}
    </>
  )
}

export default InfiniteScroll
