/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable no-param-reassign */

/**
 * @namespace page
 * @memberof models
 */

import produce from "immer"

/**
 * @memberof models.page
 * @const
 * @type {Object}
 * @property {Object} [tabName={INFERENCE: 1, MODEL: 2, ZOO: 3, USERMANAGEMENT: 4, GROUPMANAGEMENT: 5, PROFILE: 6, AUDIT: 7, DASHBOARD: 8, PREDEFINED: 9}]
 * @default
 */

const initialState = {
  sidebarCurrent: 0,
  sidebarActive: 0,
  sidebarSubMenu: "",
  tabName: {
    PROFILE: 0,
    DASHBOARD: 1,
    INFERENCE: 2,
    MODEL: 3,
    ZOO: 4,
    USERGROUPMANAGEMENT: 5,
    USERMANAGEMENT: 6,
    GROUPMANAGEMENT: 7,
    AUDIT: 8,
    PREDEFINED: 9
  },
  isSidebarCollapsed: false,
  isSidebarActive: false,
  mouseOverSidebarNum: 0,
  isWrapperDisable: false
}
const users = {
  state: initialState,
  reducers: {
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Number} payload - Changed State
     * @return {State} - Changed State
     */
    setSidebarCurrent(state, payload) {
      return produce(state, draft => {
        draft.sidebarCurrent = payload
      })
    },
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Number} payload
     * @return {State} - Changed State
     */
    setSidebarActive(state, payload) {
      return produce(state, draft => {
        draft.sidebarActive = payload
      })
    },
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Number} payload - Sidebar Sub Menu
     * @return {State} - Changed State
     */
    setSidebarSubMenu(state, payload) {
      return produce(state, draft => {
        draft.sidebarSubMenu = payload
      })
    },
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Number} payload
     * @return {State} - Changed State
     */
    setIsSidebarCollapsed(state, payload) {
      return produce(state, draft => {
        draft.isSidebarCollapsed = payload
      })
    },
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Boolean} payload
     * @return {State} - Changed State
     */
    setIsSidebarActive(state, payload) {
      return produce(state, draft => {
        draft.isSidebarActive = payload
      })
    },
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Number} payload
     * @return {State} - Changed State
     */
    setMouseOverSidebarNum(state, payload) {
      return produce(state, draft => {
        draft.mouseOverSidebarNum = payload
      })
    },
    /**
     * @memberof models.page
     * @param {State} state - Current State
     * @param {Boolean} payload
     * @return {State} - Changed State
     */
    setIsWrapperDisable(state, payload) {
      return produce(state, draft => {
        draft.isWrapperDisable = payload
      })
    }
  }
}

export default users
