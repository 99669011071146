/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useRef } from "react"
import { InputGroup, Form, Button } from "react-bootstrap"
import { faClone } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { addAlert } from "utils"

/**
 * @memberof components
 * @property  {Component} children - Children in CopyableText
 * @property  {String} value - Set inputField value
 */
const CopyableText = ({ children, value }) => {
  const textRef = useRef()
  const handleCopy = () => {
    textRef.current.select()
    document.execCommand("copy")
    addAlert("Copied to clipboard")
  }
  return (
    <div>
      <InputGroup>
        <Form.Control ref={textRef} value={value} onChange={() => {}} />
        <div className="d-flex">
          <InputGroup.Append>
            <Button
              className="px-3"
              size="sm"
              variant="light"
              style={{ border: "1px solid #e5e5e5" }}
              onClick={handleCopy}
            >
              <FontAwesomeIcon icon={faClone} />
            </Button>
          </InputGroup.Append>
          {children && <InputGroup.Append>{children}</InputGroup.Append>}
        </div>
      </InputGroup>
    </div>
  )
}

export default CopyableText

CopyableText.defaultProps = {
  value: ""
}
