/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under theo License.
 */

import React from "react"
import PropTypes from "prop-types"
import { Button, Modal } from "react-bootstrap"

const DialogModal = ({
  title,
  body,
  handleClose,
  handleSubmit,
  show,
  submitLabel
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <h1>{title}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="col-6 btn-lg btn-outline-light"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button className="col-6 btn-lg btn-secondary" onClick={handleSubmit}>
          {submitLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DialogModal

DialogModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string
}

DialogModal.defaultProps = {
  handleClose: () => {},
  handleSubmit: () => {},
  show: false,
  title: ""
}
