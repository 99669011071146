/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable no-param-reassign */
/**
 * @namespace audits
 * @memberof models
 */
import produce from "immer"
import { ListApiAuditResponse } from "arti-proto"
import { requestProtoGet } from "./api"

/**
 * @memberof models.audits
 * @const
 * @type {Object}
 * @property {Array} [audits]
 * @property {Object} [paging]
 * @property {Number} [paging.pageNumber] - Page Number
 * @property {Number} [paging.pageSize] - Size of Page Rows
 * @property {Number} [paging.totalCount] - Total counts of audits
 */
const initialState = {
  audits: [],
  paging: {
    pageNumber: 0,
    pageSize: 20,
    totalCount: 0
  }
}

const inference = {
  state: initialState,
  reducers: {
    setList(state, payload) {
      return produce(state, draft => {
        draft.list = payload
      })
    }
  },
  effects: dispatch => ({
    /**
     * Get Api Audits List
     * @memberof models.audits
     * @function
     * @param {Object} payload
     * @param {String} payload.category - Api Category [Service, Model, User]
     * @param {Number} payload.from - Start Time
     * @param {Number} payload.pageSize - Size per Page
     * @param {Number} payload.pageNumber - Page Number
     * @param {Number} payload.to - End Time
     * @param {UserId} payload.userId - User Id
     * @property {request} request
     * @property {query} request.query /audit/list
     * @property {type} request.type GET
     * @return {ListApiAuditResponse}
     */
    async getAudits(payload) {
      const { category, from, pageSize, pageNumber, to, userId } = payload
      const { data } = await requestProtoGet(
        `/audit/list?userId=${userId}&category=${category}` +
          `&from=${from}&to=${to}&size=${pageSize}&page=${pageNumber}&sort=requestedAt,desc`
      )
      const response = ListApiAuditResponse.deserializeBinary(data).toObject()
      return response
    }
  })
}

export default inference
