/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react"
import { Spinner } from "react-bootstrap"

/**
 * @memberof components
 * @property {String} text - Spinner text
 * @property {Boolean} loading - Is Loading check
 * @property {Function} onLogout - Execute a function when clicking a logout Button
 * @property {Ref} profileEl - Set reference profile elements
 */
const TableNoDataIndication = props => {
  const { text, loading } = props
  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" size="sm" variant="secondary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }
  return (
    <div className="d-flex justify-content-center">
      <p className="text-muted font-italic">{text}</p>
    </div>
  )
}

export default TableNoDataIndication
