/*
 *  Licensed under the Apache License, Version 2.0 (the “License”);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http: //www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an “AS IS” BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react"
import PropTypes from "prop-types"
import { Breadcrumb } from "react-bootstrap"
import { getRandomString } from "utils"

/**
 * @memberof components
 * @property {String} className - the color of the text in the button
 * @property {Array} items - array of BreadCrumbItems
 */
const BreadCrumbWrapper = props => {
  const { className, items } = props
  return (
    <Breadcrumb className={className}>
      {items.map(item => {
        return (
          <Breadcrumb.Item
            key={`breadcrumb-${item.name}-${getRandomString(4)}`}
            active={item.active}
            href={item.href || ""}
          >
            {item.name}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}
export default BreadCrumbWrapper

BreadCrumbWrapper.defaultProps = {
  className: "",
  items: []
}

BreadCrumbWrapper.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}
